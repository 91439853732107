<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="1200px">
      <v-card>
        <v-card-title
          >{{ $t("admin.setpermissionfile") }}&nbsp;{{
            filedata.file_name
          }}&nbsp;</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text style="height:800px">
          <v-layout row wrap class="pa-2">
            <v-flex xs12 lg12>
              <div v-if="filedata.file_type === 'folder'">
                <v-layout>
                  <v-switch
                    @change="check_clone(permission_clone)"
                    :color="color.theme"
                    inset
                    class="mx-2"
                    v-model="permission_clone"
                    :label="$t('admin.permissionclone')"
                  ></v-switch>
                  <template>
                    <!-- <div class="align-self-center mb-1">
              <v-dialog
                v-model="show_info"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    >
                      <v-icon color="gray lighten">
                      mdi-information
                      </v-icon>
                  </v-btn>
                </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                ทีมงานกำลังพัฒนา . . .
              </v-card-title>

              <v-card-text class="mt-5">
                โปรดรอ ทางทีมงานกำลังพัฒนา . . .
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="show_info = false"
                >
                  ปิดไปก่อน
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          </div> -->
                  </template>
                </v-layout>
              </div>
              <v-tabs v-model="tab" :color="color.theme">
                <v-tabs-slider :slider-color="color.theme"></v-tabs-slider>
                <!-- เก่า -->
                <!-- <v-tab>
                  <v-chip dark label :color="chipbusiness">{{
                    $t("admin.business")
                  }}</v-chip>
                </v-tab>
                <v-tab>
                  <v-chip dark label :color="chipdepartment">{{
                    $t("admin.department")
                  }}</v-chip>
                </v-tab>
                <v-tab>
                  <v-chip dark label :color="chiprole">{{
                    $t("admin.role")
                  }}</v-chip>
                </v-tab>
                <v-tab>
                  <v-chip dark label :color="chipstaff">{{
                    $t("admin.employee")
                  }}</v-chip>
                </v-tab> -->

                <!-- ใหม่ -->
                <v-tab>
                  <v-chip dark label :color="data_permission_business.permision_status ? color.theme : 'blue-grey lighten-3'">{{
                    $t("admin.business")
                  }}</v-chip>
                </v-tab>
                <v-tab>
                  <v-chip dark label :color="data_permission_department.permision_status ? color.theme : 'blue-grey lighten-3'">{{
                    $t("admin.department")
                  }}</v-chip>
                </v-tab>
                <v-tab>
                  <v-chip dark label :color="data_permission_role.permision_status ? color.theme : 'blue-grey lighten-3'">{{
                    $t("admin.role")
                  }}</v-chip>
                </v-tab>
                <v-tab>
                  <v-chip dark label :color="chipstaff">{{
                    $t("admin.employee")
                  }}</v-chip>
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <!-- business -->
                <v-tab-item>
                  <div v-if="loaddataprogress === true">
                    <v-flex lg12 class="ma-2 ml-4 mr-4">
                      <div class="text-center">
                        <v-progress-circular
                          :size="90"
                          :color="color.theme"
                          indeterminate
                          >กำลังอัปเดต</v-progress-circular
                        >
                      </div>
                    </v-flex>
                  </div>
                  <div v-else>
                    <v-alert
                      dense
                      type="warning"
                      v-if="filedata.status_updating !== 'Done'"
                      >{{ filedata.file_name }}&nbsp;{{
                        $t("admin.setupdate")
                      }}</v-alert
                    >
                    <v-flex offset-lg9 lg4 hide-details>
                      <div class="text-right">
                        <span>{{ $t("reload_permission") }}&nbsp; &nbsp;</span>
                        <v-btn
                          x-small
                          fab
                          :dark="color.darkTheme"
                          :color="color.theme"
                          @click="fn_checkreload(), (showReload = false)"
                          @mouseover="showReload = true"
                          @mouseleave="showReload = false"
                          ><v-icon small>mdi-reload</v-icon
                          ><v-badge
                            :value="showReload"
                            style="z-index:10000;bottom:-45px;left:20px;"
                            :content="$t('balloon.reload')"
                            left
                            transition="slide-y-transition"
                          ></v-badge
                        ></v-btn>
                      </div>
                      <!-- @change="opencheck('bu', permission_business)" -->
                      <v-switch
                        @change="opencheck('bu', permission_business)"
                        :color="color.theme"
                        inset
                        class="mx-2 mr-5"
                        v-model="permission_business"
                        :label="$t('admin.permissionbusiness')"
                      ></v-switch>
                      <v-row>
                        <!-- <v-btn style="margin-right:1px" class="mr-1" hide-details :dark="color.darkTheme"  :color="color.theme" @click="allsetting('d',true)" ><v-icon>check</v-icon>เปิดทั้งหมด</v-btn>  -->
                        <!-- <v-btn hide-details :dark="color.darkTheme"  :color="color.theme" @click="allsetting('d',false)" ><v-icon>close</v-icon>ปิดทั้งหมด</v-btn>  -->
                      </v-row>
                    </v-flex>
                    <div v-if="imageHeight <= 500">
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              dataAccountActive["business_info"]["first_name_th"]
                            }}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-row class="text">
                              <v-col>
                                <!-- เก่า -->
                                <!-- <v-switch
                                  :disabled="!permission_business"
                                  @change="
                                    setstatuspermissionbusiness(
                                      'v',
                                      item,
                                      item.permission_business
                                    )
                                  "
                                  class="mx-2"
                                  :color="color.theme"
                                  inset
                                  :label="$t('admin.view')"
                                  v-model="item.permission_business.view_only"
                                ></v-switch>
                                <v-switch
                                  :disabled="!permission_business"
                                  @change="
                                    setstatuspermissionbusiness(
                                      'd',
                                      item,
                                      item.permission_business
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.download')"
                                  v-model="item.permission_business.download"
                                ></v-switch>
                                <v-switch
                                  :color="color.theme"
                                  :disabled="!permission_business"
                                  @change="
                                    setstatuspermissionbusiness(
                                      'u',
                                      item,
                                      item.permission_business
                                    )
                                  "
                                  inset
                                  class="mx-2"
                                  v-model="item.permission_business.upload"
                                  :label="$t('admin.upload')"
                                ></v-switch>
                                <v-switch
                                  :disabled="!permission_business"
                                  @change="
                                    setstatuspermissionbusiness(
                                      'e',
                                      item,
                                      item.permission_business
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.edit')"
                                  v-model="item.permission_business.edit"
                                ></v-switch>
                                <v-switch
                                  :disabled="
                                    !permission_business || disableByPackage
                                  "
                                  @change="
                                    setstatuspermissionbusiness(
                                      'dl',
                                      item,
                                      item.permission_business
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.delete_s')"
                                  v-model="item.permission_business.delete"
                                ></v-switch>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-if="
                                        disableByPackage &&
                                          $t('default') === 'th'
                                      "
                                      style="margin-top:-85px; margin-left:90px;"
                                      >mdi-lock</v-icon
                                    >
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-else-if="disableByPackage"
                                      style="margin-top:-85px; margin-left:115px;"
                                      >mdi-lock</v-icon
                                    >
                                  </template>
                                  <span>{{ $t("balloon.lock_function") }}</span>
                                </v-tooltip> -->

                                <!-- ใหม่ -->
                                <v-switch
                                  :disabled="!data_permission_business['permision_status']"
                                  @change="fn_change_permission(data_permission_business['permission_active'], 'V')"
                                  class="mx-2"
                                  :color="color.theme"
                                  inset
                                  :label="$t('admin.view')"
                                  v-model="data_permission_business['permission_active'].view_only"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_business['permision_status']"
                                  @change="fn_change_permission(data_permission_business['permission_active'], 'D')"
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.download')"
                                  v-model="data_permission_business['permission_active'].download"
                                ></v-switch>
                                <v-switch
                                  :color="color.theme"
                                  :disabled="!data_permission_business['permision_status']"
                                  @change="fn_change_permission(data_permission_business['permission_active'], 'U')"
                                  inset
                                  class="mx-2"
                                  v-model="data_permission_business['permission_active'].upload"
                                  :label="$t('admin.upload')"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_business['permision_status']"
                                  @change="fn_change_permission(data_permission_business['permission_active'], 'E')"
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.edit')"
                                  v-model="data_permission_business['permission_active'].edit"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_business['permision_status']"
                                  @change="fn_change_permission(data_permission_business['permission_active'], 'DE')"
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.delete_s')"
                                  v-model="data_permission_business['permission_active'].delete"
                                ></v-switch>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-if="
                                        disableByPackage &&
                                          $t('default') === 'th'
                                      "
                                      style="margin-top:-85px; margin-left:90px;"
                                      >mdi-lock</v-icon
                                    >
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-else-if="disableByPackage"
                                      style="margin-top:-85px; margin-left:115px;"
                                      >mdi-lock</v-icon
                                    >
                                  </template>
                                  <span>{{ $t("balloon.lock_function") }}</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                    <div v-else>
                      <v-list>
                        <!-- <v-list-item v-for="item in business" :key="item.title">
                          <v-list-item-action class="pl-12 ml-10">
                            <v-list-item-title>{{
                              item.business_name
                            }}</v-list-item-title>
                          </v-list-item-action>
                          <v-list-item-content class="pl-12 ml-10">
                            <v-row class="text-center">
                              <v-switch
                                :disabled="!permission_business"
                                @change="
                                  setstatuspermissionbusiness(
                                    'v',
                                    item,
                                    item.permission_business
                                  )
                                "
                                class="mx-2"
                                :color="color.theme"
                                inset
                                :label="$t('admin.view')"
                                v-model="item.permission_business.view_only"
                              ></v-switch>
                              <v-switch
                                :disabled="!permission_business"
                                @change="
                                  setstatuspermissionbusiness(
                                    'd',
                                    item,
                                    item.permission_business
                                  )
                                "
                                :color="color.theme"
                                inset
                                class="mx-2"
                                :label="$t('admin.download')"
                                v-model="item.permission_business.download"
                              ></v-switch>
                              <v-switch
                                :color="color.theme"
                                :disabled="!permission_business"
                                @change="
                                  setstatuspermissionbusiness(
                                    'u',
                                    item,
                                    item.permission_business
                                  )
                                "
                                inset
                                class="mx-2"
                                v-model="item.permission_business.upload"
                                :label="$t('admin.upload')"
                              ></v-switch>
                              <v-switch
                                :disabled="!permission_business"
                                @change="
                                  setstatuspermissionbusiness(
                                    'e',
                                    item,
                                    item.permission_business
                                  )
                                "
                                :color="color.theme"
                                inset
                                class="mx-2"
                                :label="$t('admin.edit')"
                                v-model="item.permission_business.edit"
                              ></v-switch>
                              <v-switch
                                :disabled="
                                  !permission_business || disableByPackage
                                "
                                @change="
                                  setstatuspermissionbusiness(
                                    'dl',
                                    item,
                                    item.permission_business
                                  )
                                "
                                :color="color.theme"
                                inset
                                class="mx-2"
                                :label="$t('admin.delete_s')"
                                v-model="item.permission_business.delete"
                              ></v-switch>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    v-if="disableByPackage"
                                    >mdi-lock</v-icon
                                  >
                                </template>
                                <span>{{ $t("balloon.lock_function") }}</span>
                              </v-tooltip>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item> -->

                        <!-- ใหม่ๆ -->
                        <v-list-item>
                          <v-list-item-action class="pl-12 ml-10">
                            <v-list-item-title>{{
                              dataAccountActive["business_info"]["first_name_th"]
                            }}</v-list-item-title>
                          </v-list-item-action>
                          <v-list-item-content class="pl-12 ml-10">
                            <v-row class="text-center">
                              <v-switch
                                :disabled="!data_permission_business['permision_status']"
                                class="mx-2"
                                :color="color.theme"
                                inset
                                :label="$t('admin.view')"
                                @change="fn_change_permission(data_permission_business['permission_active'], 'V')"
                                v-model="data_permission_business['permission_active'].view_only"
                              ></v-switch>
                              <v-switch
                                :disabled="!data_permission_business['permision_status']"
                                :color="color.theme"
                                inset
                                class="mx-2"
                                :label="$t('admin.download')"
                                @change="fn_change_permission(data_permission_business['permission_active'], 'D')"
                                v-model="data_permission_business['permission_active'].download"
                              ></v-switch>
                              <v-switch
                                :color="color.theme"
                                :disabled="!data_permission_business['permision_status']"
                                inset
                                class="mx-2"
                                @change="fn_change_permission(data_permission_business['permission_active'], 'U')"
                                v-model="data_permission_business['permission_active'].upload"
                                :label="$t('admin.upload')"
                              ></v-switch>
                              <v-switch
                                :disabled="!data_permission_business['permision_status']"
                                :color="color.theme"
                                inset
                                class="mx-2"
                                :label="$t('admin.edit')"
                                @change="fn_change_permission(data_permission_business['permission_active'], 'E')"
                                v-model="data_permission_business['permission_active'].edit"
                              ></v-switch>
                              <v-switch
                                :disabled="!data_permission_business['permision_status']"
                                :color="color.theme"
                                inset
                                class="mx-2"
                                :label="$t('admin.delete_s')"
                                @change="fn_change_permission(data_permission_business['permission_active'], 'DE')"
                                v-model="data_permission_business['permission_active'].delete"
                              ></v-switch>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    v-if="disableByPackage"
                                    >mdi-lock</v-icon
                                  >
                                </template>
                                <span>{{ $t("balloon.lock_function") }}</span>
                              </v-tooltip>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </div>
                </v-tab-item>
                <!-- department -->
                <v-tab-item>
                  <div v-if="loaddataprogress === true">
                    <v-flex lg12 class="ma-2 ml-4 mr-4">
                      <div class="text-center">
                        <v-progress-circular
                          :size="90"
                          :color="color.theme"
                          indeterminate
                          >กำลังอัปเดต</v-progress-circular
                        >
                      </div>
                    </v-flex>
                  </div>
                  <div v-else>
                    <v-alert
                      dense
                      type="warning"
                      v-if="filedata.status_updating !== 'Done'"
                      >{{ filedata.file_name }}&nbsp;{{
                        $t("admin.setupdate")
                      }}</v-alert
                    >
                    <br />

                    <v-layout>
                      <v-flex lg2 v-if="resolutionScreen > 500" hide-details>
                        <v-text-field
                          hide-details
                          :label="$t('admin.search')"
                          v-model="search_department"
                          clearable
                          outlined
                          dense
                          :color="color.theme"
                        ></v-text-field>
                      </v-flex>

                      <v-flex offset-lg7 lg4 hide-details>
                        <div class="text-right">
                          <span
                            >{{ $t("reload_permission") }}&nbsp; &nbsp;</span
                          >
                          <v-btn
                            class="mr-5"
                            x-small
                            fab
                            :dark="color.darkTheme"
                            :color="color.theme"
                            @click="fn_checkreload(), (showReload = false)"
                            @mouseover="showReload = true"
                            @mouseleave="showReload = false"
                            ><v-icon small>mdi-reload</v-icon
                            ><v-badge
                              style="z-index:10000;bottom:-45px;left:20px;"
                              :value="showReload"
                              :content="$t('balloon.reload')"
                              left
                              transition="slide-y-transition"
                            ></v-badge
                          ></v-btn>
                        </div>
                        <!-- opencheck('d', data_.permision_status) -->
                        <v-switch
                          @change="
                            opencheck('d', data_permission_department.permision_status)
                          "
                          :color="color.theme"
                          inset
                          class="mx-2"
                          v-model="permission_department_setting"
                          :label="$t('admin.permissiondepartment')"
                        ></v-switch>
                        <v-row>
                          <v-btn
                            style="margin-right:1px"
                            class="mr-1"
                            hide-details
                            :dark="color.darkTheme"
                            :color="color.theme"
                            @click="allsetting('d', true)"
                            ><v-icon>check</v-icon
                            >{{ $t("admin.openall") }}</v-btn
                          >
                          <v-btn
                            hide-details
                            :dark="color.darkTheme"
                            :color="color.theme"
                            @click="allsetting('d', false)"
                            ><v-icon>close</v-icon
                            >{{ $t("admin.closeall") }}</v-btn
                          >
                        </v-row>
                      </v-flex>
                    </v-layout>
                    <v-flex lg6 hide-details>
                      <v-chip
                        class="ma-2"
                        :color="color.theme"
                        :text-color="color.chipText"
                      >
                        <v-icon left>mdi-domain</v-icon>
                        {{ countdepartment }}
                      </v-chip>
                    </v-flex>
                    <div v-if="imageHeight <= 500">
                      <v-list
                        two-line
                        class="mb-6 pb-6 elevation-2"
                        v-if="data_permission_department.permission_value.length !== 0"
                      >
                        <v-list-item
                          v-for="item in paginatedDataDepartment"
                          :key="item.department_name"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.department_name
                            }}</v-list-item-title>
                            <!-- <v-list-item-subtitle></v-list-item-subtitle> -->
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-row class="text">
                              <v-col>
                                <!-- เก่า -->
                                <!-- <v-switch
                                  :disabled="!permission_department_setting"
                                  @change="
                                    setstatuspermissiondepartment(
                                      'v',
                                      item.permission_data,
                                      item
                                    )
                                  "
                                  class="mx-2"
                                  :color="color.theme"
                                  inset
                                  :label="$t('admin.view')"
                                  v-model="item.permission_data.view_only"
                                ></v-switch>
                                <v-switch
                                  :disabled="!permission_department_setting"
                                  @change="
                                    setstatuspermissiondepartment(
                                      'd',
                                      item.permission_data,
                                      item
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.download')"
                                  v-model="item.permission_data.download"
                                ></v-switch>
                                <v-switch
                                  :color="color.theme"
                                  :disabled="!permission_department_setting"
                                  @change="
                                    setstatuspermissiondepartment(
                                      'u',
                                      item.permission_data,
                                      item
                                    )
                                  "
                                  inset
                                  class="mx-2"
                                  v-model="item.permission_data.upload"
                                  :label="$t('admin.upload')"
                                ></v-switch>
                                <v-switch
                                  :disabled="!permission_department_setting"
                                  @change="
                                    setstatuspermissiondepartment(
                                      'e',
                                      item.permission_data,
                                      item
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.edit')"
                                  v-model="item.permission_data.edit"
                                ></v-switch>
                                <v-switch
                                  :disabled="
                                    !permission_department_setting ||
                                      disableByPackage
                                  "
                                  @change="
                                    setstatuspermissiondepartment(
                                      'dl',
                                      item.permission_data,
                                      item
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.delete_s')"
                                  v-model="item.permission_data.delete"
                                ></v-switch>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-if="
                                        disableByPackage &&
                                          $t('default') === 'th'
                                      "
                                      style="margin-top:-85px; margin-left:90px;"
                                      >mdi-lock</v-icon
                                    >
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-else-if="disableByPackage"
                                      style="margin-top:-85px; margin-left:115px;"
                                      >mdi-lock</v-icon
                                    >
                                  </template>
                                  <span>{{ $t("balloon.lock_function") }}</span>
                                </v-tooltip> -->
                                
                                <!-- ใหม่ -->
                                <v-switch
                                  :disabled="!data_permission_department['permision_status']"
                                  @change="fn_change_permission(item['permission_active'], 'V')"
                                  class="mx-2"
                                  :color="color.theme"
                                  inset
                                  :label="$t('admin.view')"
                                  v-model="item.permission_active.view_only"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_department['permision_status']"
                                  @change="fn_change_permission(item['permission_active'], 'D')"
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.download')"
                                  v-model="item.permission_active.download"
                                ></v-switch>
                                <v-switch
                                  :color="color.theme"
                                  :disabled="!data_permission_department['permision_status']"
                                  @change="fn_change_permission(item['permission_active'], 'U')"
                                  inset
                                  class="mx-2"
                                  v-model="item.permission_active.upload"
                                  :label="$t('admin.upload')"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_department['permision_status']"
                                  @change="fn_change_permission(item['permission_active'], 'E')"
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.edit')"
                                  v-model="item.permission_active.edit"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_department['permision_status']"
                                  @change="fn_change_permission(item['permission_active'], 'DE')"
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.delete_s')"
                                  v-model="item.permission_active.delete"
                                ></v-switch>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-if="
                                        disableByPackage &&
                                          $t('default') === 'th'
                                      "
                                      style="margin-top:-85px; margin-left:90px;"
                                      >mdi-lock</v-icon
                                    >
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-else-if="disableByPackage"
                                      style="margin-top:-85px; margin-left:115px;"
                                      >mdi-lock</v-icon
                                    >
                                  </template>
                                  <span>{{ $t("balloon.lock_function") }}</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>                        
                      </v-list>
                      <v-list v-else class="mb-6 pb-6 elevation-2">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-center"
                              v-text="$t('tablefile.empty')"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <div v-if="pageCountDepartmentMobile > 0">
                        <v-pagination
                          :total-visible="10"
                          v-model="pageDepartment"
                          :length="pageCountDepartmentMobile"
                          :color="color.theme"
                          @input="changepage()"
                        ></v-pagination>
                      </div>
                    </div>
                    <div v-else>
                      <!-- เก่า -->
                      <!-- <v-data-table
                        :search="search_department"
                        :items="department"
                        :headers="headerdepartment"
                        class="elevation-1"
                        :page.sync="pageDepartment"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCountDepartmentDesktop = $event"
                        :no-data-text="$t('tablefile.empty')"
                      >
                        <template v-slot:[`header.department_name`]="{ header }"
                          ><span :style="headerTable">{{
                            $t(header.text)
                          }}</span></template
                        >
                        <template v-slot:[`header.status`]="{ header }"
                          ><span :style="headerTable">{{
                            $t(header.text)
                          }}</span></template
                        >
                        <template v-slot:item="props">
                          <tr style="cursor: pointer">
                            <td width="45%">
                              {{ props.item.department_name }}
                            </td>
                            <td width="55%" class="text-center">
                              <v-row class="text-center">
                                <v-switch
                                  :disabled="!permission_department_setting"
                                  @change="
                                    setstatuspermissiondepartment(
                                      'v',
                                      props.item.permission_data,
                                      props.item
                                    )
                                  "
                                  class="mx-2"
                                  :color="color.theme"
                                  inset
                                  :label="$t('admin.view')"
                                  v-model="props.item.permission_data.view_only"
                                ></v-switch>
                                <v-switch
                                  :disabled="!permission_department_setting"
                                  @change="
                                    setstatuspermissiondepartment(
                                      'd',
                                      props.item.permission_data,
                                      props.item
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.download')"
                                  v-model="props.item.permission_data.download"
                                ></v-switch>
                                <v-switch
                                  :color="color.theme"
                                  :disabled="!permission_department_setting"
                                  @change="
                                    setstatuspermissiondepartment(
                                      'u',
                                      props.item.permission_data,
                                      props.item
                                    )
                                  "
                                  inset
                                  class="mx-2"
                                  v-model="props.item.permission_data.upload"
                                  :label="$t('admin.upload')"
                                ></v-switch>
                                <v-switch
                                  :disabled="!permission_department_setting"
                                  @change="
                                    setstatuspermissiondepartment(
                                      'e',
                                      props.item.permission_data,
                                      props.item
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.edit')"
                                  v-model="props.item.permission_data.edit"
                                ></v-switch>
                                <v-switch
                                  :disabled="
                                    !permission_department_setting ||
                                      disableByPackage
                                  "
                                  @change="
                                    setstatuspermissiondepartment(
                                      'dl',
                                      props.item.permission_data,
                                      props.item
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.delete_s')"
                                  v-model="props.item.permission_data.delete"
                                ></v-switch>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-if="disableByPackage"
                                      >mdi-lock</v-icon
                                    >
                                  </template>
                                  <span>{{ $t("balloon.lock_function") }}</span>
                                </v-tooltip>
                              </v-row>
                            </td>
                          </tr>
                        </template>
                      </v-data-table> -->

                      <!-- ใหม่ -->
                      <v-data-table
                        :search="search_department"
                        :items="data_permission_department.permission_value"
                        :headers="headerdepartment"
                        class="elevation-1"
                        :page.sync="pageDepartment"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCountDepartmentDesktop = $event"
                        :no-data-text="$t('tablefile.empty')"
                      >
                        <template v-slot:[`header.department_name`]="{ header }"
                          ><span :style="headerTable">{{
                            $t(header.text)
                          }}</span></template
                        >
                        <template v-slot:[`header.status`]="{ header }"
                          ><span :style="headerTable">{{
                            $t(header.text)
                          }}</span></template
                        >
                        <template v-slot:item="props">
                          <tr style="cursor: pointer">
                            <td width="45%">
                              {{ props.item.department_name }}
                            </td>
                            <td width="55%" class="text-center">
                              <v-row class="text-center">
                                <v-switch
                                  :disabled="!data_permission_department['permision_status']"
                                  class="mx-2"
                                  :color="color.theme"
                                  inset
                                  :label="$t('admin.view')"
                                  @change="fn_change_permission(props.item['permission_active'], 'V')"
                                  v-model="props.item.permission_active.view_only"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_department['permision_status']"
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  @change="fn_change_permission(props.item['permission_active'], 'D')"
                                  :label="$t('admin.download')"
                                  v-model="props.item.permission_active.download"
                                ></v-switch>
                                <v-switch
                                  :color="color.theme"
                                   :disabled="!data_permission_department['permision_status']"
                                  inset
                                  class="mx-2"
                                  @change="fn_change_permission(props.item['permission_active'], 'U')"
                                  v-model="props.item.permission_active.upload"
                                  :label="$t('admin.upload')"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_department['permision_status']"
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.edit')"
                                  @change="fn_change_permission(props.item['permission_active'], 'E')"
                                  v-model="props.item.permission_active.edit"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_department['permision_status']"
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.delete_s')"
                                  @change="fn_change_permission(props.item['permission_active'], 'DE')"
                                  v-model="props.item.permission_active.delete"
                                ></v-switch>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-if="disableByPackage"
                                      >mdi-lock</v-icon
                                    >
                                  </template>
                                  <span>{{ $t("balloon.lock_function") }}</span>
                                </v-tooltip>
                              </v-row>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <br />
                      <div v-if="pageCountDepartmentDesktop > 0">
                        <v-pagination
                          :total-visible="10"
                          v-model="pageDepartment"
                          :length="pageCountDepartmentDesktop"
                          :color="color.theme"
                          @input="changepage()"
                        ></v-pagination>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
                <!-- role -->
                <v-tab-item>
                  <div v-if="loaddataprogress === true">
                    <v-flex lg12 class="ma-2 ml-4 mr-4">
                      <div class="text-center">
                        <v-progress-circular
                          :size="90"
                          :color="color.theme"
                          indeterminate
                          >กำลังอัปเดต</v-progress-circular
                        >
                      </div>
                    </v-flex>
                  </div>
                  <div v-else>
                    <v-alert
                      dense
                      type="warning"
                      v-if="filedata.status_updating !== 'Done'"
                      >{{ filedata.file_name }}&nbsp;{{
                        $t("admin.setupdate")
                      }}</v-alert
                    >
                    <br />

                    <v-layout>
                      <v-flex lg2 v-if="resolutionScreen > 500" hide-details>
                        <v-text-field
                          hide-details
                          :label="$t('admin.search')"
                          v-model="search_role"
                          clearable
                          outlined
                          dense
                          :color="color.theme"
                        ></v-text-field>
                      </v-flex>

                      <v-flex offset-lg7 lg4 hide-details>
                        <div class="text-right">
                          <span
                            >{{ $t("reload_permission") }}&nbsp; &nbsp;</span
                          >
                          <v-btn
                            class="mr-5"
                            x-small
                            fab
                            :dark="color.darkTheme"
                            :color="color.theme"
                            @click="fn_checkreload(), (showReload = false)"
                            @mouseover="showReload = true"
                            @mouseleave="showReload = false"
                            ><v-icon small>mdi-reload</v-icon
                            ><v-badge
                              style="z-index:10000;bottom:-45px;left:20px;"
                              :value="showReload"
                              :content="$t('balloon.reload')"
                              left
                              transition="slide-y-transition"
                            ></v-badge
                          ></v-btn>
                        </div>
                        <v-switch
                          @change="
                            opencheck('r', permission_role_setting)
                          "
                          :color="color.theme"
                          inset
                          class="mx-2"
                          v-model="permission_role_setting"
                          :label="$t('admin.permissionrole')"
                        ></v-switch>
                        <v-row>
                          <v-btn
                            style="margin-right:1px"
                            class="mr-1"
                            hide-details
                            :dark="color.darkTheme"
                            :color="color.theme"
                            @click="allsetting('r', true)"
                            ><v-icon>check</v-icon
                            >{{ $t("admin.openall") }}</v-btn
                          >
                          <v-btn
                            hide-details
                            :dark="color.darkTheme"
                            :color="color.theme"
                            @click="allsetting('r', false)"
                            ><v-icon>close</v-icon
                            >{{ $t("admin.closeall") }}</v-btn
                          >
                        </v-row>
                      </v-flex>
                    </v-layout>
                    <v-flex lg6 hide-details>
                      <v-chip
                        class="ma-2"
                        :color="color.theme"
                        :text-color="color.chipText"
                      >
                        <v-icon left>mdi-account-group</v-icon>
                        {{ countrole }}
                      </v-chip>
                    </v-flex>
                    <div v-if="imageHeight <= 500">
                      <v-list
                        two-line
                        class="mb-6 pb-6 elevation-2"
                        v-if="data_permission_role.permission_value.length !== 0"
                      >
                        <v-list-item
                          v-for="item in paginatedDataRole"
                          :key="item.role_name"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.role_name
                            }}</v-list-item-title>
                            <!-- <v-list-item-subtitle></v-list-item-subtitle> -->
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-row class="text">
                              <v-col>
                                <!-- เก่า -->
                                <!-- <v-switch
                                  :disabled="!permission_role_setting"
                                  @change="
                                    setstatuspermissionrole(
                                      'v',
                                      item.permission_data,
                                      item
                                    )
                                  "
                                  class="mx-2"
                                  :color="color.theme"
                                  inset
                                  :label="$t('admin.view')"
                                  v-model="item.permission_data.view_only"
                                ></v-switch>
                                <v-switch
                                  :disabled="!permission_role_setting"
                                  @change="
                                    setstatuspermissionrole(
                                      'd',
                                      item.permission_data,
                                      item
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.download')"
                                  v-model="item.permission_data.download"
                                ></v-switch>
                                <v-switch
                                  :color="color.theme"
                                  :disabled="!permission_role_setting"
                                  @change="
                                    setstatuspermissionrole(
                                      'u',
                                      item.permission_data,
                                      item
                                    )
                                  "
                                  inset
                                  class="mx-2"
                                  v-model="item.permission_data.upload"
                                  :label="$t('admin.upload')"
                                ></v-switch>
                                <v-switch
                                  :disabled="!permission_role_setting"
                                  @change="
                                    setstatuspermissionrole(
                                      'e',
                                      item.permission_data,
                                      item
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.edit')"
                                  v-model="item.permission_data.edit"
                                ></v-switch>
                                <v-switch
                                  :disabled="
                                    !permission_role_setting ||
                                      disableByPackage
                                  "
                                  @change="
                                    setstatuspermissionrole(
                                      'dl',
                                      item.permission_data,
                                      item
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.delete_s')"
                                  v-model="item.permission_data.delete"
                                ></v-switch>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-if="
                                        disableByPackage &&
                                          $t('default') === 'th'
                                      "
                                      style="margin-top:-85px; margin-left:90px;"
                                      >mdi-lock</v-icon
                                    >
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-else-if="disableByPackage"
                                      style="margin-top:-85px; margin-left:115px;"
                                      >mdi-lock</v-icon
                                    >
                                  </template>
                                  <span>{{ $t("balloon.lock_function") }}</span>
                                </v-tooltip> -->

                                <!-- ใหม่ๆ -->
                                <v-switch
                                  :disabled="!data_permission_role['permision_status']"
                                  @change="fn_change_permission(item['permission_active'], 'V')"
                                  class="mx-2"
                                  :color="color.theme"
                                  inset
                                  :label="$t('admin.view')"
                                  v-model="item.permission_active.view_only"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_role['permision_status']"
                                  @change="fn_change_permission(item['permission_active'], 'D')"
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.download')"
                                  v-model="item.permission_active.download"
                                ></v-switch>
                                <v-switch
                                  :color="color.theme"
                                  :disabled="!data_permission_role['permision_status']"
                                   @change="fn_change_permission(item['permission_active'], 'U')"
                                  inset
                                  class="mx-2"
                                  v-model="item.permission_active.upload"
                                  :label="$t('admin.upload')"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_role['permision_status']"
                                  @change="fn_change_permission(item['permission_active'], 'E')"
                                  inset
                                  :color="color.theme"
                                  class="mx-2"
                                  :label="$t('admin.edit')"
                                  v-model="item.permission_active.edit"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_role['permision_status']"
                                  @change="fn_change_permission(item['permission_active'], 'DE')"
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.delete_s')"
                                  v-model="item.permission_active.delete"
                                ></v-switch>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-if="
                                        disableByPackage &&
                                          $t('default') === 'th'
                                      "
                                      style="margin-top:-85px; margin-left:90px;"
                                      >mdi-lock</v-icon
                                    >
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-else-if="disableByPackage"
                                      style="margin-top:-85px; margin-left:115px;"
                                      >mdi-lock</v-icon
                                    >
                                  </template>
                                  <span>{{ $t("balloon.lock_function") }}</span>
                                </v-tooltip>
                                
                              </v-col>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-list v-else class="mb-6 pb-6 elevation-2">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-center"
                              v-text="$t('tablefile.empty')"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <div v-if="pageCountRoleMobile > 0">
                        <v-pagination
                          :total-visible="10"
                          v-model="pageRole"
                          :length="pageCountRoleMobile"
                          :color="color.theme"
                          @input="changepage()"
                        ></v-pagination>
                      </div>
                    </div>
                    <div v-else>
                      <!-- <v-data-table
                        :search="search_role"
                        :items="role"
                        :headers="headerrole"
                        class="elevation-1"
                        :page.sync="pageRole"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCountRoleDesktop = $event"
                        :no-data-text="$t('tablefile.empty')"
                      >
                        <template v-slot:[`header.role_name`]="{ header }"
                          ><span :style="headerTable">{{
                            $t(header.text)
                          }}</span></template
                        >
                        <template v-slot:[`header.status`]="{ header }"
                          ><span :style="headerTable">{{
                            $t(header.text)
                          }}</span></template
                        >
                        <template v-slot:item="props">
                          <tr style="cursor: pointer">
                            <td width="45%">
                              {{ props.item.role_name }}
                            </td>
                            <td width="55%" class="text-center">
                              <v-row class="text-center">
                                <v-switch
                                  :disabled="!permission_role_setting"
                                  @change="
                                    setstatuspermissionrole(
                                      'v',
                                      props.item.permission_data,
                                      props.item
                                    )
                                  "
                                  class="mx-2"
                                  :color="color.theme"
                                  inset
                                  :label="$t('admin.view')"
                                  v-model="props.item.permission_data.view_only"
                                ></v-switch>
                                <v-switch
                                  :disabled="!permission_role_setting"
                                  @change="
                                    setstatuspermissionrole(
                                      'd',
                                      props.item.permission_data,
                                      props.item
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.download')"
                                  v-model="props.item.permission_data.download"
                                ></v-switch>
                                <v-switch
                                  :color="color.theme"
                                  :disabled="!permission_role_setting"
                                  @change="
                                    setstatuspermissionrole(
                                      'u',
                                      props.item.permission_data,
                                      props.item
                                    )
                                  "
                                  inset
                                  class="mx-2"
                                  v-model="props.item.permission_data.upload"
                                  :label="$t('admin.upload')"
                                ></v-switch>
                                <v-switch
                                  :disabled="!permission_role_setting"
                                  @change="
                                    setstatuspermissionrole(
                                      'e',
                                      props.item.permission_data,
                                      props.item
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.edit')"
                                  v-model="props.item.permission_data.edit"
                                ></v-switch>
                                <v-switch
                                  :disabled="
                                    !permission_role_setting ||
                                      disableByPackage
                                  "
                                  @change="
                                    setstatuspermissionrole(
                                      'dl',
                                      props.item.permission_data,
                                      props.item
                                    )
                                  "
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.delete_s')"
                                  v-model="props.item.permission_data.delete"
                                ></v-switch>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-if="disableByPackage"
                                      >mdi-lock</v-icon
                                    >
                                  </template>
                                  <span>{{ $t("balloon.lock_function") }}</span>
                                </v-tooltip>
                              </v-row>
                            </td>
                          </tr>
                        </template>
                      </v-data-table> -->

                      <!-- ใหม่ -->
                      <v-data-table
                        :search="search_role"
                        :items="data_permission_role.permission_value"
                        :headers="headerrole"
                        class="elevation-1"
                        :page.sync="pageRole"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCountRoleDesktop = $event"
                        :no-data-text="$t('tablefile.empty')"
                      >
                        <template v-slot:[`header.role_name`]="{ header }"
                          ><span :style="headerTable">{{
                            $t(header.text)
                          }}</span></template
                        >
                        <template v-slot:[`header.status`]="{ header }"
                          ><span :style="headerTable">{{
                            $t(header.text)
                          }}</span></template
                        >
                        <template v-slot:item="props">
                          <tr style="cursor: pointer">
                            <td width="45%">
                              {{ props.item.role_name }}
                            </td>
                            <td width="55%" class="text-center">
                              <v-row class="text-center">
                                <v-switch
                                  :disabled="!data_permission_role['permision_status']"
                                  @change="fn_change_permission(props.item['permission_active'], 'V')"
                                  class="mx-2"
                                  :color="color.theme"
                                  inset
                                  :label="$t('admin.view')"
                                  v-model="props.item.permission_active.view_only"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_role['permision_status']"
                                  @change="fn_change_permission(props.item['permission_active'], 'D')"
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.download')"
                                  v-model="props.item.permission_active.download"
                                ></v-switch>
                                <v-switch
                                  :color="color.theme"
                                  :disabled="!data_permission_role['permision_status']"
                                  @change="fn_change_permission(props.item['permission_active'], 'U')"
                                  inset
                                  class="mx-2"
                                  v-model="props.item.permission_active.upload"
                                  :label="$t('admin.upload')"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_role['permision_status']"
                                  @change="fn_change_permission(props.item['permission_active'], 'E')"
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.edit')"
                                  v-model="props.item.permission_active.edit"
                                ></v-switch>
                                <v-switch
                                  :disabled="!data_permission_role['permision_status']"
                                  @change="fn_change_permission(props.item['permission_active'], 'DE')"
                                  :color="color.theme"
                                  inset
                                  class="mx-2"
                                  :label="$t('admin.delete_s')"
                                  v-model="props.item.permission_active.delete"
                                ></v-switch>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      v-if="disableByPackage"
                                      >mdi-lock</v-icon
                                    >
                                  </template>
                                  <span>{{ $t("balloon.lock_function") }}</span>
                                </v-tooltip>
                              </v-row>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <br />
                      <div v-if="pageCountRoleDesktop > 0">
                        <v-pagination
                          :total-visible="10"
                          v-model="pageRole"
                          :length="pageCountRoleDesktop || 0"
                          :color="color.theme"
                          @input="changepage()"
                        ></v-pagination>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
                <!-- staff -->
                <v-tab-item>
                  <div v-if="loaddataprogressstaff === true">
                    <v-flex lg12 class="ma-2 ml-4 mr-4">
                      <div class="text-center">
                        <v-progress-circular
                          :size="90"
                          :color="color.theme"
                          indeterminate
                          >กำลังอัปเดต</v-progress-circular
                        >
                      </div>
                    </v-flex>
                  </div>
                  <div v-else>
                    <v-alert
                      dense
                      type="warning"
                      v-if="filedata.status_updating !== 'Done'"
                      >{{ filedata.file_name }}&nbsp;{{
                        $t("admin.setupdate")
                      }}</v-alert
                    >
                    <br />
                    <v-container>
                      <v-layout>
                        <!-- <v-flex lg2 hide-details>
                          <v-select
                            v-model="selectdepartment"
                            :items="filterdepartment"
                            :label="$t('admin.filterdepartment')"
                            item-text="department_name"
                            item-value="department_id"
                            outlined
                            dense
                            :color="color.theme"
                            :item-color="color.theme"
                            @change="fn_load_filter_staff()"
                            hide-details
                          ></v-select>
                        </v-flex> -->
                        <v-flex lg3 v-if="resolutionScreen > 500" hide-details>
                          <v-text-field
                            hide-details
                            class="pl-1"
                            :label="$t('admin.search')"
                            v-model="search_em"
                            clearable
                            @click:clear="search_em='',fn_load_filter_staff()"
                            outlined
                            dense
                            :color="color.theme"
                            @keyup.enter="fn_load_filter_staff()"
                          ></v-text-field>
                        </v-flex>
                        <v-flex lg2 v-if="resolutionScreen > 500" hide-details>
                          <v-btn 
                            class="ma-1 text-center"  
                            :color="color.theme"
                            :dark="color.darkTheme" 
                            @click="fn_load_filter_staff()"
                          >
                          {{ $t("admin.search") }}
                          </v-btn>
                        </v-flex>
                        <!-- </v-flex> -->
                        <v-flex offset-lg5 lg4 hide-details>
                          <div class="text-right">
                            <span
                              >{{ $t("reload_permission") }}&nbsp; &nbsp;</span
                            >
                            <v-btn
                              class="mr-5"
                              x-small
                              fab
                              :dark="color.darkTheme"
                              :color="color.theme"
                              @click="fn_checkreload(), (showReload = false)"
                              @mouseover="showReload = true"
                              @mouseleave="showReload = false"
                              ><v-icon small>mdi-reload</v-icon
                              ><v-badge
                                style="z-index:10000;bottom:-45px;left:20px;"
                                :value="showReload"
                                :content="$t('balloon.reload')"
                                left
                                transition="slide-y-transition"
                              ></v-badge
                            ></v-btn>
                          </div>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex lg4>
                          <div v-if="statusmutiple === false" class="pt-2">
                            <v-btn
                              @mouseover="show_menu = true"
                              @mouseleave="show_menu = false"
                              :color="color.theme"
                              :dark="color.darkTheme"
                              class="ma-1"
                              fab
                              small
                              outlined
                              @click="
                                (statusmutiple = true), (show_menu = false)
                              "
                            >
                              <v-icon>apps</v-icon>
                              <v-badge
                                v-if="$t('default') === 'th'"
                                style="z-index:10000;bottom:-45px;left:-1px;"
                                :value="show_menu"
                                :content="$t('balloon.menu')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                              <v-badge
                                v-else
                                style="z-index:10000;bottom:-45px;left:6px;"
                                :value="show_menu"
                                :content="$t('balloon.menu')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                            </v-btn>
                          </div>
                          <div v-else class="pt-2">
                            <v-btn
                              small
                              fab
                              :color="color.theme"
                              :dark="color.darkTheme"
                              class="ma-1"
                              @click="fn_multisetpermission('v')"
                              @mouseover="showviewonly = true"
                              @mouseleave="showviewonly = false"
                            >
                              <v-icon>mdi-eye</v-icon>
                              <v-badge
                                v-if="$t('default') === 'th'"
                                style="z-index:10000;bottom:-45px;left:15px;"
                                :value="showviewonly"
                                :content="$t('balloon.view')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                              <v-badge
                                v-else
                                style="z-index:10000;bottom:-45px;left:25px;"
                                :value="showviewonly"
                                :content="$t('balloon.view')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                            </v-btn>
                            <v-btn
                              fab
                              small
                              :color="color.theme"
                              :dark="color.darkTheme"
                              class="ma-1"
                              @click="fn_multisetpermission('d')"
                              @mouseover="showdownload = true"
                              @mouseleave="showdownload = false"
                            >
                              <v-icon>mdi-download</v-icon>
                              <v-badge
                                v-if="$t('default') === 'th'"
                                style="z-index:10000;bottom:-45px;left:17px;"
                                :value="showdownload"
                                :content="$t('balloon.download')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                              <v-badge
                                v-else
                                style="z-index:10000;bottom:-45px;left:20px;"
                                :value="showdownload"
                                :content="$t('balloon.download')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                            </v-btn>
                            <v-btn
                              :dark="color.darkTheme"
                              :color="color.theme"
                              small
                              class="ma-1"
                              fab
                              @click="fn_multisetpermission('u')"
                              @mouseover="showupload = true"
                              @mouseleave="showupload = false"
                            >
                              <v-icon dark>mdi-cloud-upload</v-icon>
                              <v-badge
                                v-if="$t('default') === 'th'"
                                style="z-index:10000;bottom:-45px;left:12px;"
                                :value="showupload"
                                :content="$t('balloon.upload')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                              <v-badge
                                v-else
                                style="z-index:10000;bottom:-45px;left:12px;"
                                :value="showupload"
                                :content="$t('balloon.upload')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                            </v-btn>
                            <v-btn
                              fab
                              small
                              class="ma-1"
                              :color="color.theme"
                              :dark="color.darkTheme"
                              @click="fn_multisetpermission('e')"
                              @mouseover="showedit = true"
                              @mouseleave="showedit = false"
                            >
                              <v-icon>mdi-pencil</v-icon>
                              <v-badge
                                v-if="$t('default') === 'th'"
                                style="z-index:10000;bottom:-45px;left:3px;"
                                :value="showedit"
                                :content="$t('balloon.edit')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                              <v-badge
                                v-else
                                style="z-index:10000;bottom:-45px;left:3px;"
                                :value="showedit"
                                :content="$t('balloon.edit')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                            </v-btn>
                            <v-btn
                              fab
                              small
                              class="ma-1"
                              :color="color.theme"
                              :dark="color.darkTheme"
                              @click="fn_multisetpermission('dl')"
                              @mouseover="showdelete = true"
                              @mouseleave="showdelete = false"
                            >
                              <v-icon>mdi-trash-can</v-icon>
                              <v-badge
                                v-if="$t('default') === 'th'"
                                style="z-index:10000;bottom:-45px;left:-1px;"
                                :value="showdelete"
                                :content="$t('balloon.delete')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                              <v-badge
                                v-else
                                style="z-index:10000;bottom:-45px;left:10px;"
                                :value="showdelete"
                                :content="$t('balloon.delete')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                            </v-btn>
                            <v-btn
                              fab
                              small
                              class="ma-1"
                              :color="color.theme"
                              :dark="color.darkTheme"
                              @click="fn_multisetpermission('a')"
                              @mouseover="showadmin = true"
                              @mouseleave="showadmin = false"
                            >
                              <v-icon>mdi-account-cog</v-icon>
                              <v-badge
                                v-if="$t('default') === 'th'"
                                style="z-index:10000;bottom:-45px;left:6px;"
                                :value="showadmin"
                                :content="$t('balloon.admin')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                              <v-badge
                                v-else
                                style="z-index:10000;bottom:-45px;left:45px;"
                                :value="showadmin"
                                :content="$t('balloon.admin')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                            </v-btn>
                            <v-btn
                              @mouseover="show_clear = true"
                              @mouseleave="show_clear = false"
                              color="error"
                              fab
                              small
                              class="ma-1"
                              @click="
                                fn_multisetpermission('cc'),
                                  (show_clear = false)
                              "
                            >
                              <v-icon>clear</v-icon>
                              <v-badge
                                v-if="$t('default') === 'th'"
                                style="z-index:10000;bottom:-45px;left:-2px;"
                                :value="show_clear"
                                :content="$t('balloon.clear')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                              <v-badge
                                v-else
                                style="z-index:10000;bottom:-45px;left:7px;"
                                :value="show_clear"
                                :content="$t('balloon.clear')"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                            </v-btn>
                          </div>
                        </v-flex>
                        <v-flex offset-lg5 lg3>
                          <v-switch
                            @change="opencheck('e', permission_file)"
                            :color="color.theme"
                            inset
                            class="mx-2"
                            v-model="permission_file"
                            :label="$t('admin.permissionemployee')"
                          ></v-switch>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex offset-lg9 lg3>
                          <v-btn
                            style="magrin-right:1px;"
                            class="mr-1"
                            hide-details
                            :dark="color.darkTheme"
                            :color="color.theme"
                            @click="allsetting('e', true)"
                            ><v-icon>check</v-icon
                            >{{ $t("admin.openall") }}</v-btn
                          >
                          <v-btn
                            hide-details
                            :dark="color.darkTheme"
                            :color="color.theme"
                            @click="allsetting('e', false)"
                            ><v-icon>close</v-icon
                            >{{ $t("admin.closeall") }}</v-btn
                          ></v-flex
                        >
                      </v-layout>
                      <v-flex lg6>
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>mdi-account</v-icon>
                          {{ countemployee }}
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>mdi-domain</v-icon>
                          {{ countdepartment }}
                        </v-chip>
                      </v-flex>
                      <div v-if="imageHeight <= 500">
                        <v-list
                          two-line
                          class="mb-6 pb-6 elevation-2"
                          v-if="staff.length !== 0"
                        >
                          <v-list-item
                            v-for="item in paginatedDataStaff"
                            :key="item.title"
                          >
                            <v-list-item-action
                              ><v-checkbox
                                v-if="statusmutiple === true"
                                v-model="select_staff"
                                :value="item.staff_id"
                                :color="color.theme"
                                hide-details
                              ></v-checkbox
                            ></v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                v-if="$t('default') === 'en'"
                                >{{ item.staff_name_en }}</v-list-item-title
                              >
                              <v-list-item-title v-else>{{
                                item.staff_name_th
                              }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                item.email
                              }}</v-list-item-subtitle>
                              <v-list-item-subtitle>{{
                                item.department_name
                              }}</v-list-item-subtitle>
                                
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-row class="text">
                                <v-col>
                                  <v-switch
                                    @change="
                                      setstatuspermission(
                                        'v',
                                        item.permission_data,
                                        item
                                      )
                                    "
                                    class="mx-2"
                                    :color="color.theme"
                                    :disabled="!permission_file"
                                    inset
                                    v-model="item.permission_data.view_only"
                                    :label="$t('admin.view')"
                                  ></v-switch>
                                  <v-switch
                                    :color="color.theme"
                                    :disabled="!permission_file"
                                    @change="
                                      setstatuspermission(
                                        'd',
                                        item.permission_data,
                                        item
                                      )
                                    "
                                    inset
                                    class="mx-2"
                                    v-model="item.permission_data.download"
                                    :label="$t('admin.download')"
                                  ></v-switch>
                                  <v-switch
                                    :color="color.theme"
                                    :disabled="!permission_file"
                                    @change="
                                      setstatuspermission(
                                        'u',
                                        item.permission_data,
                                        item
                                      )
                                    "
                                    inset
                                    class="mx-2"
                                    v-model="item.permission_data.upload"
                                    :label="$t('admin.upload')"
                                  ></v-switch>
                                  <v-switch
                                    :color="color.theme"
                                    :disabled="!permission_file"
                                    @change="
                                      setstatuspermission(
                                        'e',
                                        item.permission_data,
                                        item
                                      )
                                    "
                                    inset
                                    class="mx-2"
                                    v-model="item.permission_data.edit"
                                    :label="$t('admin.edit')"
                                  ></v-switch>
                                  <v-switch
                                    :color="color.theme"
                                    :disabled="
                                      !permission_file || disableByPackage
                                    "
                                    @change="
                                      setstatuspermission(
                                        'dl',
                                        item.permission_data,
                                        item
                                      )
                                    "
                                    inset
                                    class="mx-2"
                                    v-model="item.permission_data.delete"
                                    :label="$t('admin.delete_s')"
                                  ></v-switch>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        v-if="
                                          disableByPackage &&
                                            $t('default') === 'th'
                                        "
                                        style="margin-top:-85px; margin-left:90px;"
                                        >mdi-lock</v-icon
                                      >
                                      <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        v-else-if="disableByPackage"
                                        style="margin-top:-85px; margin-left:115px;"
                                        >mdi-lock</v-icon
                                      >
                                    </template>
                                    <span>{{
                                      $t("balloon.lock_function")
                                    }}</span>
                                  </v-tooltip>
                                  <v-switch
                                    :color="color.theme"
                                    :disabled="
                                      !permission_file || disableByPackage
                                    "
                                    @change="
                                      setstatuspermission(
                                        'a',
                                        item.permission_data,
                                        item
                                      )
                                    "
                                    inset
                                    class="mx-2"
                                    v-model="item.permission_data.admin_data"
                                    :label="$t('admin.admin_folder')"
                                  ></v-switch>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        v-if="
                                          disableByPackage &&
                                            $t('default') === 'th'
                                        "
                                        style="margin-top:-85px; margin-left:110px;"
                                        >mdi-lock</v-icon
                                      >
                                      <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        v-else-if="disableByPackage"
                                        style="margin-top:-85px; margin-left:115px;"
                                        >mdi-lock</v-icon
                                      >
                                    </template>
                                    <span>{{
                                      $t("balloon.lock_function")
                                    }}</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <v-list v-else class="mb-6 pb-6 elevation-2">
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                class="text-center"
                                v-text="$t('tablefile.empty')"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                      <div v-else>
                        <v-data-table
                          :items="staff"
                          :headers="$t('default') === 'en' ? header : header1"
                          class="elevation-1"
                          :page.sync="pageStaff"
                          :items-per-page="size"
                          :hide-default-footer="true"
                          @page-count="pageCountStaff = $event"
                          :no-data-text="$t('tablefile.empty')"
                        >
                          <template
                            v-slot:[`header.admin_folder`]="{ header }"
                            v-if="statusmutiple === true"
                            ><span :style="headerTable">{{
                              $t(header.text)
                            }}</span></template
                          >
                          <template
                            v-if="$t('default') === 'th'"
                            v-slot:[`header.staff_name_th`]="{ header }"
                            ><span :style="headerTable">{{
                              $t(header.text)
                            }}</span></template
                          >
                          <template
                            v-else
                            v-slot:[`header.staff_name_en`]="{ header }"
                            ><span :style="headerTable">{{
                              $t(header.text)
                            }}</span></template
                          >
                          <template v-slot:[`header.email`]="{ header }"
                            ><span :style="headerTable">{{
                              $t(header.text)
                            }}</span></template
                          >
                          <!-- <template v-slot:[`header.department`]="{ header }"
                            ><span :style="headerTable">{{
                              $t(header.text)
                            }}</span></template
                          > -->
                          <template v-slot:[`header.status`]="{ header }"
                            ><span :style="headerTable">{{
                              $t(header.text)
                            }}</span></template
                          >
                          <template v-slot:item="props">
                            <tr style="cursor: pointer">
                              <td width="7%">
                                <v-checkbox
                                  v-if="statusmutiple === true"
                                  v-model="select_staff"
                                  :value="props.item.staff_id"
                                  :color="color.theme"
                                  hide-details
                                ></v-checkbox>
                              </td>
                              <td width="15%" v-if="$t('default') === 'th'">
                                {{ props.item.staff_name_th }}
                              </td>
                              <td width="15%" v-else>
                                {{ props.item.staff_name_en }}
                              </td>
                              <td width="12%">
                                {{ props.item.email }}
                              </td>
                              <!-- <td width="12%">
                                {{ props.item.department_name }}
                              </td> -->
                              <td width="60%" class="text-center">
                                <v-row class="text-center">
                                  <v-switch
                                    @change="
                                      setstatuspermission(
                                        'v',
                                        props.item.permission_data,
                                        props.item
                                      )
                                    "
                                    class="mx-2"
                                    :color="color.theme"
                                    :disabled="!permission_file"
                                    inset
                                    v-model="
                                      props.item.permission_data.view_only
                                    "
                                    :label="$t('admin.view')"
                                  ></v-switch>
                                  <v-switch
                                    :color="color.theme"
                                    :disabled="!permission_file"
                                    @change="
                                      setstatuspermission(
                                        'd',
                                        props.item.permission_data,
                                        props.item
                                      )
                                    "
                                    inset
                                    class="mx-2"
                                    v-model="
                                      props.item.permission_data.download
                                    "
                                    :label="$t('admin.download')"
                                  ></v-switch>
                                  <v-switch
                                    :color="color.theme"
                                    :disabled="!permission_file"
                                    @change="
                                      setstatuspermission(
                                        'u',
                                        props.item.permission_data,
                                        props.item
                                      )
                                    "
                                    inset
                                    class="mx-2"
                                    v-model="props.item.permission_data.upload"
                                    :label="$t('admin.upload')"
                                  ></v-switch>
                                  <v-switch
                                    :color="color.theme"
                                    :disabled="!permission_file"
                                    @change="
                                      setstatuspermission(
                                        'e',
                                        props.item.permission_data,
                                        props.item
                                      )
                                    "
                                    inset
                                    class="mx-2"
                                    v-model="props.item.permission_data.edit"
                                    :label="$t('admin.edit')"
                                  ></v-switch>
                                  <v-switch
                                    :color="color.theme"
                                    :disabled="
                                      !permission_file || disableByPackage
                                    "
                                    @change="
                                      setstatuspermission(
                                        'dl',
                                        props.item.permission_data,
                                        props.item
                                      )
                                    "
                                    inset
                                    class="mx-2"
                                    v-model="props.item.permission_data.delete"
                                    :label="$t('admin.delete_s')"
                                  ></v-switch>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        v-if="disableByPackage"
                                        >mdi-lock</v-icon
                                      >
                                    </template>
                                    <span>{{
                                      $t("balloon.lock_function")
                                    }}</span>
                                  </v-tooltip>

                                  <v-switch
                                    :color="color.theme"
                                    :disabled="
                                      !permission_file || disableByPackage
                                    "
                                    @change="
                                      setstatuspermission(
                                        'a',
                                        props.item.permission_data,
                                        props.item
                                      )
                                    "
                                    inset
                                    class="mx-2"
                                    v-model="
                                      props.item.permission_data.admin_data
                                    "
                                    :label="$t('admin.admin_folder')"
                                  >
                                  </v-switch>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        v-if="disableByPackage"
                                        >mdi-lock</v-icon
                                      >
                                    </template>
                                    <span>{{
                                      $t("balloon.lock_function")
                                    }}</span>
                                  </v-tooltip>
                                </v-row>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </div>
                      <br />
                      <div v-if="pageCountStaff > 0">
                        <v-pagination
                          :total-visible="10"
                          v-model="pageStaff"
                          :length="pageCountStaff || 0"
                          :color="color.theme"
                          @input="changepage()"
                        ></v-pagination>
                      </div>
                    </v-container>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="closeDialog()"
            >{{ $t("admin.close") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="opencheck('b'), (statusmutiple = false)"
            >{{ $t("admin.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogcheck
      :show="opencheckpermission"
      @confrimsetting="(confrimsetting = true), checktap()"
      @notsetting="(notsetting = true), checktap()"
      @confrimsettingbutton="(confrimsettingbutton = true), checktapbutton()"
      @notsettingbutton="(confrimsettingbutton = false, notsettingbutton = true), checktapbutton()"
      @close="opencheckpermission = false"
      :keyset="statuscheck"
      :keymode="modecheck"
      :keybutton="statusbutton"
      :file="filedata"
    ></dialogcheck>
    <dialogcheckreload
      :show="opencheckreload"
      @close="opencheckreload = false"
      @reload="fn_reload_v2(), (opencheckreload = false)"
    ></dialogcheckreload>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const dialogcheck = () => import("@/components/optional/dialog-checksetting");
const dialogcheckreload = () =>
  import("@/components/optional/dialog-confirmreloadpermission");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: ["show", "filedata"],
  components: { dialogcheck, dialogcheckreload },
  data: function() {
    return {
      // สร้าง object ของ permission
      data_permission: [
        {
          permission_name: "Business",
          permision_status: null, 
          permission_number: "", 
          permission_active: {},
        },
        {
          permission_name: "Deparment",
          permision_status: null, 
          permission_number: "", 
          permission_active: {},
        },
        {
          permission_name: "Role",
          permision_status: null, 
          permission_number: "", 
          permission_active: {},
        },
        {
          permission_name: "Employees",
          permision_status: null, 
          permission_number: "", 
          permission_active: {},
        }
      ],
      data_permission_business: {
          permission_name: "Business",
          permision_status: null, 
          permission_number: "", 
          permission_active: {},
        },
      data_permission_department: {
          permission_name: "Department",
          permision_status: null,
          permission_value: [],
        }, 
      data_permission_role: {
          permission_name: "Role",
          permision_status: null, 
          permission_value: [],
        },
      data_permission_employees: {
          permission_name: "Business",
          permision_status: null, 
          permission_number: "", 
          permission_active: {},
        },
      backup_compare_role: "",
      backup_compare_business: "",
      backup_compare_department: "",
      backup_compare_staff: "",
      backup_compar_header_staff: "",
      isApiBusiness: false,
      isApiDepartment: false,
      isApiRole: false,
      isApiEmploy: false,
      check_api_permission: [
        {id: 1, name: 'business', use_api: false},
        {id: 2, name: 'department', use_api: false},
        {id: 3, name: 'role', use_api: false},
        {id: 4, name: 'employee', use_api: false}
      ],
      isLoadingPermission: false,
      
      pageDepartment: 1,
      pageRole: 1,
      pageStaff: 1,
      pageCountDepartmentDesktop: 0,
      pageCountRoleDesktop: 0,
      role:[],
      Buff_role:[],
      opencheckreload: false,
      show_info: false,
      permission_clone: false,
      showviewonly: false,
      showdownload: false,
      showupload: false,
      showedit: false,
      showdelete: false,
      showadmin: false,
      show_clear: false,
      show_menu: false,
      statusmutiple: false,
      select_staff: [],
      buff_loop_staff: [],
      checkregD: false,
      checkregS: false,
      Buff_business: [],
      Buff_department: [],
      Buff_staff: [],
      checkRes: "",
      checkCC: false,
      showReload: false,
      business: [],
      labelallsetting: "เปิดทั้งหมด",
      all_setting: false,
      listsize: [10, 20, 50, 100],
      size: 20,
      page: 1,
      countemployee: 0,
      countdepartment: 0,
      countrole: 0,
      loaddataprogress: true,
      loaddataprogressstaff: true,
      opencheckpermission: false,
      confrimsetting: false,
      notsetting: false,
      confrimsettingbutton: false,
      notsettingbutton: false,
      timeout: 10000000000,
      statuscheck: true,
      modecheck: "",
      statusbutton: false,
      snackbar: false,
      tab: null,
      search: "",
      search_department: "",
      search_role: "",
      search_em: "",
      editpermission: false,
      itemsstatus: [
        { name: "view", status: "y" },
        { name: "edit", status: "e" },
        { name: "delete", status: "d" },
      ],
      functionset: ["department", "staff"],
      filterdepartment: [],
      chipbusiness: "blue-grey lighten-3",
      chipstaff: "blue-grey lighten-3",
      chipdepartment: "blue-grey lighten-3",
      chiprole: "blue-grey lighten-3",
      selectset: "department",
      selectdepartment: "",
      permission_file: false,
      permission_department_setting: false,
      permission_role_setting:false,
      permission_business: false,
      disabled: false,
      staff: [],
      department: [],
      test: 1,
      header: [
        {
          text: "",
          align: "",
          value: "admin_folder",
          width: "5%",
          sortable: false,
        },
        {
          text: "admin.name",
          align: "",
          value: "staff_name_en",
          width: "20%",
          sortable: false,
        },
        {
          text: "admin.emailtext",
          align: "",
          value: "email",
          width: "12%",
          sortable: false,
        },
        // {
        //   text: "admin.department",
        //   align: "",
        //   value: "department",
        //   width: "12%",
        //   sortable: false,
        // },
        {
          text: "admin.status",
          align: "center",
          value: "status",
          width: "60%",
          sortable: false,
        },
      ],
      header1: [
        {
          text: "",
          align: "",
          value: "admin_folder",
          width: "5%",
          sortable: false,
        },
        {
          text: "admin.name",
          align: "",
          value: "staff_name_th",
          width: "20%",
          sortable: false,
        },
        {
          text: "admin.emailtext",
          align: "",
          value: "email",
          width: "12%",
          sortable: false,
        },
        // {
        //   text: "admin.department",
        //   align: "",
        //   value: "department",
        //   width: "12%",
        //   sortable: false,
        // },
        {
          text: "admin.status",
          align: "center",
          value: "status",
          width: "60%",
          sortable: false,
        },
      ],
      headerdepartment: [
        {
          text: "admin.name",
          align: "",
          value: "department_name",
          width: "40%",
          sortable: false,
        },
        {
          text: "admin.status",
          align: "center",
          value: "status",
          width: "60%",
          sortable: false,
        },
      ],
      headerrole: [
        {
          text: "admin.role",
          align: "",
          value: "role_name",
          width: "40%",
          sortable: false,
        },
        {
          text: "admin.status",
          align: "center",
          value: "status",
          width: "60%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataCheckPackage",
    ]),
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        //\\ if(this.show === true){
        //   if(this.test === 1){
        //   console.log(this.filedata);
        //   this.loadstaff();
        //   this.checktap();
        //   }
        //   this.test=0;
        //  console.log("opensetpermission");
        // }

        // this.$emit('closeDrag');
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      },
    },
    pageCountStaff: {
      get() {
        let l = this.staff.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    pageCountDepartmentMobile: {
      get() {
        //let l = this.department.length;
        let l = this.data_permission_department.permission_value.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    pageCountRoleMobile: {
      get() {
        //let l = this.role.length;
        let l = this.data_permission_role.permission_value.length;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedDataStaff() {
      const start = (this.pageStaff - 1) * this.size;
      const end = start + this.size;
      return this.staff.slice(start, start + this.size);
    },
    paginatedDataDepartment() {
      const start = (this.pageDepartment - 1) * this.size;
      const end = start + this.size;
      return this.data_permission_department.permission_value.slice(start, start + this.size);
      //return this.department.slice(start, start + this.size);
    },
    paginatedDataRole() {
      const start = (this.pageRole - 1) * this.size;
      const end = start + this.size;
      
      console.log("sliceee ",this.data_permission_role.permission_value.slice(start, start + this.size))
      return this.data_permission_role.permission_value.slice(start, start + this.size);
      //return this.role.slice(start, start + this.size);
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    disableByPackage() {
      // return this.dataCheckPackage.type === 'Business' ? this.dataCheckPackage.package ? false : true : this.dataCheckPackage.package ? false : true
      return false;
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
        console.log("1234",this.filedata);
        this.$emit("closeDrag");
        
        //this.loadstaff();
        // this.checktap();
        // this.isLoadingPermission = true
        // this.loaddataprogress = true
        this.load_permission_v2 ()
        // this.checkTab_v2 ()
        this.checkCC = false;
      } else {
        this.$emit("openDrag");
      }
    },
    data_permission_business: {
      deep: true,
      handler (newVal, oldVal) {
        let finded_ =  this.check_api_permission.find((item) => 
            item.name === 'business'
          )
        console.log("finedd ", finded_)
        if(JSON.stringify(newVal) !== this.backup_compare_business){
          console.log("USE API BUSINESS PERMISSION")
          this.isApiBusiness = true
          finded_.use_api = true
        } else {
          console.log("NO USE API BUSINESS PERMISSION")
          this.isApiBusiness = false
          finded_.use_api = false
        }
      },
    },
    data_permission_department: {
      deep: true,
      handler (newVal, oldVal) {
        let finded_ =  this.check_api_permission.find((item) =>
            item.name === 'department'
          )
        if(JSON.stringify(newVal) !== this.backup_compare_department){
          console.log("USE API DEPARTMENT PERMISSION")
          this.isApiDepartment = true
          finded_.use_api = true
        } else {
          console.log("NO USE API DEPARTMENT PERMISSION")
          this.isApiDepartment = false
          finded_.use_api = false
        }
      }
    },
    data_permission_role: {
      deep: true,
      handler (newVal, oldVal) {
        let finded_ =  this.check_api_permission.find((item) =>
            item.name === 'role'
          )
        if(JSON.stringify(newVal) !== this.backup_compare_role){
          console.log("USE API ROLE PERMISSION")
          this.isApiRole = true
          finded_.use_api = true
        } else {
          console.log("NO USE API ROLE PERMISSION")
          this.isApiRole = false
          finded_.use_api = false
        }
      }
    },
    permission_file (newVal, oldVal) {
      let finded_ =  this.check_api_permission.find((item) => 
            item.name === 'employee'
        )
      if(JSON.stringify(newVal) !== this.backup_compar_header_staff) {
        console.log("header staff use api")
          this.isApiEmploy = true
          finded_.use_api = true
      } else {
        if(JSON.stringify(this.buff_loop_staff) !== this.backup_compare_staff){
          console.log("header staff not use api")
          this.isApiEmploy = true
          finded_.use_api = true
        } else {
          finded_.use_api = false
        }
        // this.isApiEmploy = true
        // finded_.use_api = false
      }
    },
    buff_loop_staff: {
      deep: true,
      handler (newVal, oldVal) {
        let finded_ =  this.check_api_permission.find((item) => 
            item.name === 'employee'
          )
        if(JSON.stringify(newVal) !== this.backup_compare_staff){
          console.log("USE API Employee PERMISSION")
          this.isApiEmploy = true
          finded_.use_api = true
        } else {
          console.log("NO USE API Employee PERMISSION")
          this.isApiEmploy = false
          finded_.use_api = false
        }
      }
    }

  },
  methods: {
    sortObjectKeys(obj) {
      const orderedKeys = ['view_only', 'download', 'upload', 'edit', 'delete'];
      const sortedObj = {};

      orderedKeys.forEach(key => {
        if (key in obj) {
          sortedObj[key] = obj[key];
        }
      });

      return sortedObj;
    },
    fn_change_permission (item, type) { // [v, d, u, e, d]
      console.log(item)
      let sorted_permission = this.sortObjectKeys(item)
      let array_ = Object.keys(sorted_permission)
      if(type === 'V'){
        if(!sorted_permission.view_only){
          array_.forEach((itemKey) => {
            if(itemKey !== 'view_only'){
              item[itemKey] = false
            }
          })
        } 
      } else if (type === 'D') {
        let indexSpecific = array_.indexOf('download')
        if(!sorted_permission.download){
          array_.forEach((itemKey, index) => {
            if(index < indexSpecific){
              item[itemKey] = true
            } else {
              item[itemKey] = false
            }
          })
        } else {
          array_.forEach((itemKey, index) => {
            if(index <= indexSpecific){
              item[itemKey] = true
            }
          })
        }
      } else if (type === 'U') {
        let indexSpecific = array_.indexOf('upload')
        if(!sorted_permission.upload){
          array_.forEach((itemKey, index) => {
            if(index < indexSpecific){
              item[itemKey] = true
            } else {
              item[itemKey] = false
            }
          })
        } else {
          array_.forEach((itemKey, index) => {
            if(index <= indexSpecific){
              item[itemKey] = true
            }
          })
        }
      } else if (type === 'E') {
        let indexSpecific = array_.indexOf('edit')
        if(!sorted_permission.edit){
          array_.forEach((itemKey, index) => {
            if(index < indexSpecific){
              item[itemKey] = true
            } else {
              item[itemKey] = false
            }
          })
        } else {
          array_.forEach((itemKey, index) => {
            if(index <= indexSpecific){
              item[itemKey] = true
            }
          })
        }
      } else {
        if(sorted_permission.delete){
          array_.forEach((itemKey) => {
            if(itemKey !== 'delete'){
              item[itemKey] = true
            }
          })
        }
      }
    },
    convert_bool_permission (permission) { 
      Object.keys(permission).forEach((item) => {
        permission[item] = permission[item] === "True" ? true : false
      })
      return permission
    },
    async load_permission_v2 () {
      // ของเก่าา
      
      this.loaddataprogress = true;
      this.loaddataprogressstaff = true;
      this.permission_file = this.settoboolean(
        this.filedata.permission_setting ||
          this.filedata.file_permission_setting ||
          ""
      );
      this.permission_department_setting = this.settoboolean(
        this.filedata.permission_department_setting ||
          this.filedata.file_permission_department_setting ||
          ""
      );
      this.permission_role_setting = this.settoboolean(
        this.filedata.permission_role_setting ||
          this.filedata.permission_role_setting ||
          ""
      );
      this.permission_business = this.settoboolean(
        this.filedata.permission_business_setting ||
          this.filedata.file_permission_business_setting ||
          ""
      );
      // แค่ของ Staff
      let staff = [];
      this.staff = [];
      this.Buff_staff = []; //เอาไว้ตอนเช็คsetpermission เทียบกันเพื่อหาตัวที่ตั้งค่า เพื่อส่งแค่ตัวที่ตั้งค่าให้ backend
      let buff_loop_staff = [];
      this.buff_loop_staff = []; //เอาไว้ฟิลเตอร์แผนกของพนักงาน
      this.chipstaff = this.permission_file ? this.color.theme : "blue-grey lighten-3";

      this.data_permission_business["permision_status"] =  this.filedata["permission_business_setting"] === "True" ? true : false   // BUSINESS 
      this.data_permission_department["permision_status"] =  this.filedata["permission_department_setting"] === "True" ? true : false  // Department 
      this.data_permission_role["permision_status"] =  this.filedata["permission_role_setting"] === "True" ? true : false  // role 
      this.data_permission_employees["permision_status"] =  this.filedata["permission_setting"] === "True" ? true : false  // emplayees
      // Department
      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        data_id: this.filedata.file_id,
        data_type: this.filedata.file_type,
        account_id: this.dataAccountId,
        accesstoken: this.dataAccesstoken,
        api_type: "setting_file_folder",
        search: this.search_em === "" ? "" : this.search_em
      };
      let auth = await gbfGenerate.generateToken();
      try{
        this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_MANAGE_ACCOUNT +
            "/api/v2/select_account_in_business",
          data: payload,
          headers: { Authorization: auth.code },
        })
        .then((response) => {
            if (response.data.status === "OK") {
              console.log("reponse permsiio ", response)
              // Business
              this.data_permission_business['permission_active'] =  this.convert_bool_permission (response.data.permission_business[0]['permission_business'])
              this.data_permission_business['permission_number'] = response.data.permission_business[0]['permission_business_v2']
              // Department
              this.data_permission_department['permission_value'] = response.data.permission_department
              this.data_permission_department['permission_value'].forEach((item) => {
                item['permission_active'] = this.convert_bool_permission (item['permission_department'])  
                delete item['permission_department']
              })
              // Role 
              this.data_permission_role['permission_value'] = response.data.permission_role
              this.data_permission_role['permission_value'].forEach((item) => {
                item['permission_active'] = this.convert_bool_permission (item['permission_role'])  
                delete item['permission_role']
              })

              this.countdepartment = this.data_permission_department['permission_value'].length
              this.countrole = this.data_permission_role['permission_value'].length

              // Employee
              for (let i = 0;i < response.data.permission_employee.length;i++) {
                let datastaff = {};
                datastaff["staff_id"] = response.data.permission_employee[i]["account_id"];
                datastaff["staff_name_en"] = response.data.permission_employee[i]["account_title_eng"] + " " + response.data.permission_employee[i]["first_name_eng"];
                datastaff["staff_name_th"] = response.data.permission_employee[i]["account_title_th"] + " " + response.data.permission_employee[i]["first_name_th"];
                if (response.data.permission_employee[i]["permission_data"] === "") {
                  datastaff["permission_data"] = {
                    upload: false,
                    view_only: false,
                    download: false,
                    edit: false,
                    delete: false,
                    admin_data: false,
                  };
                } else {
                  datastaff["permission_data"] = this.settoboolean(
                    response.data.permission_employee[i]["permission_data"]
                  );
                }
                if(response.data.permission_employee[i]["business_email_user"] === '' || response.data.permission_employee[i]["business_email_user"] === null || response.data.permission_employee[i]["business_email_user"] === undefined ){
                  if(response.data.permission_employee[i]["email"] === '' || response.data.permission_employee[i]["email"] === null){
                    datastaff["email"] = response.data.permission_employee[i]["thai_email"];
                  }else{
                    datastaff["email"] = response.data.permission_employee[i]["email"];
                  }
                }else{
                  datastaff["email"] = response.data.permission_employee[i]["business_email_user"];
                }
                datastaff["department_id"] = response.data.permission_employee[i]["department_id"];
                datastaff["department_name"] = response.data.permission_employee[i]["department_name"];
                staff.push(datastaff);
                this.staff.push(datastaff);
                this.buff_loop_staff.push(datastaff);
                this.Buff_staff.push(datastaff);
              }
              this.countemployee = this.staff.length;
            





              //  SET BACKUP FOR COMPARING 
              this.backup_compare_business = JSON.stringify(this.data_permission_business)
              this.backup_compare_department = JSON.stringify(this.data_permission_department)
              this.backup_compare_role = JSON.stringify(this.data_permission_role)
              this.backup_compare_staff = JSON.stringify(this.buff_loop_staff)
              this.backup_compar_header_staff = JSON.stringify(this.data_permission_employees.permision_status)
              console.log('role permission ', this.buff_loop_staff)
              this.loaddataprogress = false
              this.loaddataprogressstaff = false
            } else {

            }
        })
        .catch((err) => {
          console.log("Error something", err)
        })
      } catch (err) {
        console.log('Erorr ', err)
      }

    },
    checkTab_v2 () {
      console.log("this.notsetting",this.notsetting);
      console.log("this.modecheck ",this.modecheck );
    },
    closeDialog(){
      this.$emit('closenoreload');
      this.statusmutiple = false;
      this.search_department = '';
      this.search_role = '';
      this.search_em = '';
      this.selectdepartment = '';
      this.pageDepartment = 1;
      this.pageRole = 1;
      this.pageStaff = 1;
      this.chipstaff = "blue-grey lighten-3"
      this.check_api_permission = [
        {id: 1, name: 'business', use_api: false},
        {id: 2, name: 'department', use_api: false},
        {id: 3, name: 'role', use_api: false},
        {id: 4, name: 'employee', use_api: false}
      ]
      this.permission_clone = false
    },
    fn_checkreload() {
      this.opencheckreload = true;
    },
    // ฟังก์ชันเลือกตั้งค่าพนักงานทีละหลายคน
    fn_multisetpermission(status) {
      //console.log("select_staff",this.select_staff,status);
      if (status === "cc") {
        this.statusmutiple = false;
        this.select_staff = [];
      }
      for (let i = 0; i < this.staff.length; i++) {
        for (let j = 0; j < this.select_staff.length; j++) {
          //console.log("------ยังไม่เข้า---------------------",this.select_staff[i],this.staff[i]["staff_id"]);
          if (this.staff[i]["staff_id"] === this.select_staff[j]) {
            //console.log("เข้ามาแล้ววววววว---------------------",this.select_staff[i],this.staff[i]["staff_id"]);
            if (status === "v") {
              // เลือก ดูเท่านั้น
              this.staff[i].permission_data.view_only = true;
              this.staff[i].permission_data.download = false;
              this.staff[i].permission_data.upload = false;
              this.staff[i].permission_data.edit = false;
              this.staff[i].permission_data.delete = false;
              this.staff[i].permission_data.admin_data = false;
              
              // เพิ่มดักจับ
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.download = false;
              this.buff_loop_staff[i].permission_data.upload = false;
              this.buff_loop_staff[i].permission_data.edit = false;
              this.buff_loop_staff[i].permission_data.delete = false;
              this.buff_loop_staff[i].permission_data.admin_data = false;
              //console.log("เข้ามาแล้ววววววววววววววววว",status);
            } else if (status === "u") {
              // เลือก อัปโหลดได้
              this.staff[i].permission_data.view_only = true;
              this.staff[i].permission_data.download = true;
              this.staff[i].permission_data.upload = true;
              this.staff[i].permission_data.edit = false;
              this.staff[i].permission_data.delete = false;
              this.staff[i].permission_data.admin_data = false;

              // เพิ่มม
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.download = true;
              this.buff_loop_staff[i].permission_data.upload = true;
              this.buff_loop_staff[i].permission_data.edit = false;
              this.buff_loop_staff[i].permission_data.delete = false;
              this.buff_loop_staff[i].permission_data.admin_data = false;
              //console.log("เข้ามาแล้ววววววววววววววววว",status);
            } else if (status === "d") {
              // เลือก ดาวน์โหลดได้
              this.staff[i].permission_data.view_only = true;
              this.staff[i].permission_data.download = true;
              this.staff[i].permission_data.upload = false;
              this.staff[i].permission_data.edit = false;
              this.staff[i].permission_data.delete = false;
              this.staff[i].permission_data.admin_data = false;

              //เพิ่ม
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.download = true;
              this.buff_loop_staff[i].permission_data.upload = false;
              this.buff_loop_staff[i].permission_data.edit = false;
              this.buff_loop_staff[i].permission_data.delete = false;
              this.buff_loop_staff[i].permission_data.admin_data = false;
              // console.log("เข้ามาแล้ววววววววววววววววว",status);
            } else if (status === "e") {
              // เลือก แก้ไข
              this.staff[i].permission_data.view_only = true;
              this.staff[i].permission_data.download = true;
              this.staff[i].permission_data.upload = true;
              this.staff[i].permission_data.edit = true;
              this.staff[i].permission_data.delete = false;
              this.staff[i].permission_data.admin_data = false;

              //เพิ่ม
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.download = true;
              this.buff_loop_staff[i].permission_data.upload = true;
              this.buff_loop_staff[i].permission_data.edit = true;
              this.buff_loop_staff[i].permission_data.delete = false;
              this.buff_loop_staff[i].permission_data.admin_data = false;
              //console.log("เข้ามาแล้ววววววววววววววววว",status);
            } else if (status === "dl") {
              // เลือก ลบได้
              this.staff[i].permission_data.view_only = true;
              this.staff[i].permission_data.download = true;
              this.staff[i].permission_data.upload = true;
              this.staff[i].permission_data.edit = true;
              this.staff[i].permission_data.delete = true;
              this.staff[i].permission_data.admin_data = false;


              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.download = true;
              this.buff_loop_staff[i].permission_data.upload = true;
              this.buff_loop_staff[i].permission_data.edit = true;
              this.buff_loop_staff[i].permission_data.delete = true;
              this.buff_loop_staff[i].permission_data.admin_data = false;
              //console.log("เข้ามาแล้ววววววววววววววววว",status);
            } else if (status === "a") {
              // เลือก แอดมิน
              this.staff[i].permission_data.view_only = true;
              this.staff[i].permission_data.download = true;
              this.staff[i].permission_data.upload = true;
              this.staff[i].permission_data.edit = true;
              this.staff[i].permission_data.delete = true;
              this.staff[i].permission_data.admin_data = true;

              // เพิ่ม
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.download = true;
              this.buff_loop_staff[i].permission_data.upload = true;
              this.buff_loop_staff[i].permission_data.edit = true;
              this.buff_loop_staff[i].permission_data.delete = true;
              this.buff_loop_staff[i].permission_data.admin_data = true;
              //console.log("เข้ามาแล้ววววววววววววววววว",status);
            }
          }
        }
        //console.log(this.staff);
      }
    },
    // ฟังก์ชันฟิลเตอร์แผนกสำหรับพนักงาน
    fn_load_filter_staff() {
      this.statusmutiple = false;
      this.select_staff = [];
      // this.staff = this.buff_loop_staff;
      let searchEm = this.search_em === null ? '' : this.search_em;
      this.staff = this.buff_loop_staff.filter((item) =>
        // (item) => item.department_name.includes(this.selectdepartment) &&
        // (item.email.includes(searchEm) ||
        // item.thai_email.includes(searchEm) ||
        // item.business_email_user.includes(searchEm) ||
        // item.staff_name_en.includes(searchEm) ||
        // item.staff_name_th.includes(searchEm) ||
        // item.mobile.includes(searchEm) ||
        // item.username.includes(searchEm))
        item.email.includes(searchEm) || 
        item.staff_name_en.includes(searchEm) || 
        item.staff_name_th.includes(searchEm)
      );
      this.countemployee = this.staff.length;
      // if (this.selectdepartment === "") {
      //   raw_staff = this.staff;
      // }
        // let raw_buff_staff = this.buff_loop_staff.filter((department) => department.department_name === this.selectdepartment);
        // console.log(this.selectdepartment);
        // for (let i = 0; i < this.Buff_staff.length; i++) {
          //   if (this.selectdepartment !== "") {
            //     if (this.selectdepartment === this.Buff_staff[i]["department_name"]) {
      //       this.staff.push(this.Buff_staff[i]);
      //     }
      //   } else {
        //     this.staff.push(this.Buff_staff[i]);
        //   }
        // }
      // this.staff = raw_staff;
      // this.Buff_staff = raw_buff_staff;
      //console.log("rawstaff", raw_staff);
      //console.log(this.staff, this.buff_loop_staff, this.Buff_staff);
    },
    setadminfolder(parameter, item, data) {
      // console.log(parameter);
      for (let i = 0; i < this.staff.length; i++) {
        if (this.staff[i].staff_id === data.staff_id) {
          if (parameter === true) {
            this.staff[i].permission_data.admin_data = true;
            this.staff[i].permission_data.view_only = true;
            this.staff[i].permission_data.upload = true;
            this.staff[i].permission_data.download = true;
            this.staff[i].permission_data.edit = true;
            this.staff[i].permission_data.delete = true;
            this.staff[i].admin_folder = true;
          } else {
            this.staff[i].permission_data.admin_data = false;
            this.staff[i].admin_folder = false;
          }
        }
      }
      //console.log("staffset", this.staff);
    },
    allsetting(status, parameter) {
      //console.log(parameter);
      // if (status === "d") {
      //   if (parameter === true) {
      //     for (let i = 0; i < this.department.length; i++) {
      //       this.department[i]["permission_data"].view_only = true;
      //       this.department[i]["permission_data"].download = true;
      //       this.department[i]["permission_data"].edit = true;
      //       this.department[i]["permission_data"].delete = true;
      //       this.department[i]["permission_data"].upload = true;

      //       this.department[i]["permission_data"].admin_data = true;
      //     }
      //     this.labelallsetting = "ปิดทั้งหมด";
      //     //console.log("allsetting",this.department);
      //   } else {
      //     for (let i = 0; i < this.department.length; i++) {
      //       this.department[i]["permission_data"].view_only = false;
      //       this.department[i]["permission_data"].download = false;
      //       this.department[i]["permission_data"].edit = false;
      //       this.department[i]["permission_data"].delete = false;
      //       this.department[i]["permission_data"].upload = false;
      //       this.department[i]["permission_data"].admin_data = false;
      //     }
      //     //console.log("allsetting",this.department);
      //     this.labelallsetting = "เปิดทั้งหมด";
      //   }
      // } else if (status === "r"){
      //   if (parameter === true) {
      //     for (let i = 0; i < this.role.length; i++) {
      //       this.role[i]["permission_data"].view_only = true;
      //       this.role[i]["permission_data"].download = true;
      //       this.role[i]["permission_data"].edit = true;
      //       this.role[i]["permission_data"].delete = true;
      //       this.role[i]["permission_data"].upload = true;

      //       this.role[i]["permission_data"].admin_data = true;
      //     }
      //     this.labelallsetting = "ปิดทั้งหมด";
      //     //console.log("allsetting",this.department);
      //   } else {
      //     for (let i = 0; i < this.role.length; i++) {
      //       this.role[i]["permission_data"].view_only = false;
      //       this.role[i]["permission_data"].download = false;
      //       this.role[i]["permission_data"].edit = false;
      //       this.role[i]["permission_data"].delete = false;
      //       this.role[i]["permission_data"].upload = false;
      //       this.role[i]["permission_data"].admin_data = false;
      //     }
      //     //console.log("allsetting",this.department);
      //     this.labelallsetting = "เปิดทั้งหมด";
      //   }
      // }
      // else {
      //   if (parameter === true) {
      //     for (let i = 0; i < this.staff.length; i++) {
      //       this.staff[i]["permission_data"].view_only = true;
      //       this.staff[i]["permission_data"].download = true;
      //       this.staff[i]["permission_data"].edit = true;
      //       this.staff[i]["permission_data"].delete = true;
      //       this.staff[i]["permission_data"].upload = true;
      //       this.staff[i]["permission_data"].admin_data = true;
      //     }
      //     this.labelallsetting = "ปิดทั้งหมด";
      //     //console.log("allsetting",this.staff);
      //   } else {
      //     for (let i = 0; i < this.staff.length; i++) {
      //       this.staff[i]["permission_data"].view_only = false;
      //       this.staff[i]["permission_data"].download = false;
      //       this.staff[i]["permission_data"].edit = false;
      //       this.staff[i]["permission_data"].delete = false;
      //       this.staff[i]["permission_data"].upload = false;
      //       this.staff[i]["permission_data"].admin_data = false;
      //     }
      //     this.labelallsetting = "เปิดทั้งหมด";
      //     //console.log("allsetting",this.staff);
      //   }
      // }

      if(status === 'd') {
          if(parameter) {
            this.data_permission_department.permission_value.forEach((item) => {
              let key_permission = Object.keys(item.permission_active)
              key_permission.forEach((itemKey) => {
                item.permission_active[itemKey] = true
              })
            })
          } else {
            this.data_permission_department.permission_value.forEach((item) => {
              let key_permission = Object.keys(item.permission_active)
              key_permission.forEach((itemKey) => {
                item.permission_active[itemKey] = false
              })
            })
          }
      } else if (status === 'r') {
        if(parameter) {
            this.data_permission_role.permission_value.forEach((item) => {
              let key_permission = Object.keys(item.permission_active)
              key_permission.forEach((itemKey) => {
                item.permission_active[itemKey] = true
              })
            })
          } else {
            this.data_permission_role.permission_value.forEach((item) => {
              let key_permission = Object.keys(item.permission_active)
              key_permission.forEach((itemKey) => {
                item.permission_active[itemKey] = false
              })
            })
          }
      } else {
          if (parameter === true) {
            console.log(this.buff_loop_staff)
            console.log(this.staff)
          for (let i = 0; i < this.buff_loop_staff.length; i++) {
            this.buff_loop_staff[i]["permission_data"].view_only = true;
            this.staff[i]["permission_data"].view_only = true;
            this.buff_loop_staff[i]["permission_data"].download = true;
            this.staff[i]["permission_data"].download = true;
            this.buff_loop_staff[i]["permission_data"].edit = true;
            this.staff[i]["permission_data"].edit = true;
            this.buff_loop_staff[i]["permission_data"].delete = true;
            this.staff[i]["permission_data"].delete = true;
            this.buff_loop_staff[i]["permission_data"].upload = true;
            this.staff[i]["permission_data"].upload = true;
            this.buff_loop_staff[i]["permission_data"].admin_data = true;
            this.staff[i]["permission_data"].admin_data = true;
          }
          this.labelallsetting = "ปิดทั้งหมด";
          //console.log("allsetting",this.staff);
        } else {
          for (let i = 0; i < this.buff_loop_staff.length; i++) {
            this.buff_loop_staff[i]["permission_data"].view_only = false;
            this.staff[i]["permission_data"].view_only = false;
            this.buff_loop_staff[i]["permission_data"].download = false;
            this.staff[i]["permission_data"].download = false;
            this.buff_loop_staff[i]["permission_data"].edit = false;
            this.staff[i]["permission_data"].edit = false;
            this.buff_loop_staff[i]["permission_data"].delete = false;
            this.staff[i]["permission_data"].delete = false;
            this.buff_loop_staff[i]["permission_data"].upload = false;
            this.staff[i]["permission_data"].upload = false;
            this.buff_loop_staff[i]["permission_data"].admin_data = false;
            this.staff[i]["permission_data"].admin_data = false;
          }
          this.labelallsetting = "เปิดทั้งหมด";
          //console.log("allsetting",this.staff);
        }
      }

    },
    changepage() {
      this.loaddataprogress = true;
      setTimeout(() => {
        this.loaddataprogress = false;
      }, 1000);
    },
    opencheck(status, parameter) {
      // เก่า
      //console.log("fff", status);
      if (status === "b") {
        this.statusbutton = true;
      } else {
        this.statuscheck = parameter;
        this.modecheck = status;
        this.statusbutton = false;
      }

      //console.log("fgj",this.statuscheck);
      this.opencheckpermission = true;

      // ใหม่
      // if(status === 'b'){
      //   this.statusbutton = true;
      // } else {
      //   this.statuscheck = parameter;
      //   this.modecheck = status;
      //   this.statusbutton = false;
      // }
      
      // this.opencheckpermission = true;
    },
    check_clone(parameter) {
      console.log("check_clone", parameter);
      this.statuscheck = parameter;
      this.modecheck = "clone";
      if (parameter === true) {
        this.opencheckpermission = true;
      }
    },
    checktapbutton() {
      if (this.confrimsettingbutton === true) {                
        // this.setpermissionfile();
        this.setpermissionfile_v2 ()
        this.statusbutton = false;
        this.search_department = '';
        this.search_role = '';
        this.search_em = '';
        this.selectdepartment = '';
        this.pageDepartment = 1;
        this.pageRole = 1;
        this.pageStaff = 1;
      } else {
        this.statusbutton = false;
        //console.log("closecheck");
      }
    },
    checktap() {      
      console.log("this.notsetting",this.notsetting);
      console.log("this.modecheck ",this.modecheck );
      console.log("this.permission_role_setting",this.permission_role_setting);
      console.log("permission_department_setting",this.permission_department_setting);
      if (this.notsetting === true) {
        if (this.modecheck === "e") {
          this.permission_file = !this.permission_file;
          this.notsetting = false;
          //console.log(this.statuscheck, this.permission_file, this.notsetting);
        } else if (this.modecheck === "bu") {
          this.permission_business = !this.permission_business;
          this.notsetting = false;
          //console.log(this.modecheck, this.statuscheck, !this.permission_business, this.notsetting);
        } else if (this.modecheck === "clone") {
          this.permission_clone = !this.permission_clone;
          this.notsetting = false;
        } else {
          this.permission_department_setting = !this
            .permission_department_setting;
          this.permission_role_setting = !this
            .permission_role_setting;
          this.notsetting = false;
          //(this.statuscheck, this.permission_department_setting, this.notsetting);
        }
      } else {
        console.log("this.permission_role_setting",this.permission_role_setting);
        console.log("permission setting ", this.permission_business)
        console.log("HELLO ", this.permission_file)
        if (this.permission_department_setting === false) {
          this.data_permission_department.permision_status = false
          this.chipdepartment = "blue-grey lighten-3";
          this.selectset = "staff";
        } else {
          this.data_permission_department.permision_status = true
          this.chipdepartment = this.color.theme;
          this.selectset = "staff";
        }

        if (this.permission_role_setting === false) {
          this.data_permission_role.permision_status = false
          this.chiprole = "blue-grey lighten-3";
          this.selectset = "role";
        } else {
          this.data_permission_role.permision_status = true
          this.chiprole = this.color.theme;
          this.selectset = "role";
        }

        if (this.permission_file === false) {
          this.permission_file = false
          this.chipstaff = "blue-grey lighten-3";
          this.selectset = "department";
        } else {
          this.permission_file = true
          this.chipstaff = this.color.theme;
          this.selectset = "department";
        }

        if (this.permission_business === false) {
          this.data_permission_business.permision_status = false
          this.chipbusiness = "blue-grey lighten-3";
          this.selectset = "business";
        } else {
          this.data_permission_business.permision_status = true
          this.chipbusiness = this.color.theme;
          this.selectset = "business";
        }
      }
    },
    setstatuspermission(status, parameter, data) {
      // console.log(status, parameter, data, data.staff_id);
      for (let i = 0; i < this.buff_loop_staff.length; i++) {
        if (
          this.buff_loop_staff[i].staff_id === data.staff_id &&
          this.buff_loop_staff[i].department_id === data.department_id
        ) {
          // console.log("dd", data.staff_id, this.buff_loop_staff[i].staff_name_th);
          if (status === "d") {
            if (parameter.download === true) {
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.download = true;
              this.buff_loop_staff[i].permission_data.upload = false;
              this.buff_loop_staff[i].permission_data.edit = false;
              this.buff_loop_staff[i].permission_data.delete = false;
              this.buff_loop_staff[i].permission_data.admin_data = false;
            } else if (parameter.download === false) {
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.download = false;
              this.buff_loop_staff[i].permission_data.upload = false;
              this.buff_loop_staff[i].permission_data.edit = false;
              this.buff_loop_staff[i].permission_data.delete = false;
              this.buff_loop_staff[i].permission_data.admin_data = false;
            }
          } else if (status === "v") {
            if (parameter.view_only === true) {
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.edit = false;
              this.buff_loop_staff[i].permission_data.download = false;
              this.buff_loop_staff[i].permission_data.upload = false;
              this.buff_loop_staff[i].permission_data.delete = false;
              this.buff_loop_staff[i].permission_data.admin_data = false;
            } else if (parameter.view_only === false) {
              this.buff_loop_staff[i].permission_data.download = false;
              this.buff_loop_staff[i].permission_data.upload = false;
              this.buff_loop_staff[i].permission_data.edit = false;
              this.buff_loop_staff[i].permission_data.view_only = false;
              this.buff_loop_staff[i].permission_data.delete = false;
              this.buff_loop_staff[i].permission_data.admin_data = false;
              this.buff_loop_staff[i].permission_data.admin_data = false;
            }
          } else if (status === "e") {
            //console.log("a");
            if (parameter.edit === true) {
              // console.log("b");
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.download = true;
              this.buff_loop_staff[i].permission_data.upload = true;
              this.buff_loop_staff[i].permission_data.edit = true;
              this.buff_loop_staff[i].permission_data.delete = false;
              this.buff_loop_staff[i].permission_data.admin_data = false;

              // console.log("2",this.buff_loop_staff[i].permission_data.view_only,this.buff_loop_staff[i].staff_name_th);
            } else if (parameter.edit === false) {
              // console.log("c");
              this.buff_loop_staff[i].permission_data.download = true;
              this.buff_loop_staff[i].permission_data.upload = true;
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.edit = false;
              this.buff_loop_staff[i].permission_data.delete = false;
              this.buff_loop_staff[i].permission_data.admin_data = false;
            }
          } else if (status === "dl") {
            //console.log("dl");
            if (parameter.delete === true) {
              // console.log("b");
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.download = true;
              this.buff_loop_staff[i].permission_data.edit = true;
              this.buff_loop_staff[i].permission_data.delete = true;
              this.buff_loop_staff[i].permission_data.upload = true;
              this.buff_loop_staff[i].permission_data.admin_data = false;

              // console.log("2", this.buff_loop_staff[i].permission_data.view_only, this.buff_loop_staff[i].staff_name_th);
            } else if (parameter.delete === false) {
              // console.log("c");
              this.buff_loop_staff[i].permission_data.download = true;
              this.buff_loop_staff[i].permission_data.upload = true;
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.edit = true;
              this.buff_loop_staff[i].permission_data.delete = false;
              this.buff_loop_staff[i].permission_data.admin_data = false;
            }
          } else if (status === "u") {
            // console.log("u");
            if (parameter.upload === true) {
              // console.log("b");
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.download = true;
              this.buff_loop_staff[i].permission_data.upload = true;
              this.buff_loop_staff[i].permission_data.edit = false;
              this.buff_loop_staff[i].permission_data.delete = false;
              this.buff_loop_staff[i].permission_data.admin_data = false;

              // console.log("2", this.buff_loop_staff[i].permission_data.view_only, this.buff_loop_staff[i].staff_name_th);
            } else if (parameter.upload === false) {
              // console.log("c");
              this.buff_loop_staff[i].permission_data.download = true;
              this.buff_loop_staff[i].permission_data.upload = false;
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.edit = false;
              this.buff_loop_staff[i].permission_data.delete = false;
              this.buff_loop_staff[i].permission_data.admin_data = false;
            }
          } else if (status === "a") {
            // console.log("a");
            if (parameter.admin_data === true) {
              // console.log("b");
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.download = true;
              this.buff_loop_staff[i].permission_data.upload = true;
              this.buff_loop_staff[i].permission_data.edit = true;
              this.buff_loop_staff[i].permission_data.delete = true;
              this.buff_loop_staff[i].permission_data.admin_data = true;
              // console.log("2", this.buff_loop_staff[i].permission_data.view_only, this.buff_loop_staff[i].staff_name_th);
            } else if (parameter.admin_data === false) {
              // console.log("c");
              this.buff_loop_staff[i].permission_data.download = true;
              this.buff_loop_staff[i].permission_data.upload = true;
              this.buff_loop_staff[i].permission_data.view_only = true;
              this.buff_loop_staff[i].permission_data.edit = true;
              this.buff_loop_staff[i].permission_data.delete = true;
              this.buff_loop_staff[i].permission_data.admin_data = false;
            }
          }
        }
        //console.log("1",this.buff_loop_staff[i].permission_data,this.buff_loop_staff[i].staff_name_th);
      }
      // console.log("setttttstafffff", this.buff_loop_staff, this.buff_loop_staff, this.Buff_staff);
      this.fn_load_filter_staff()
    },
    setstatuspermissiondepartment(status, parameter, data) {
      //console.log(status, parameter, data.department_id);
      for (let i = 0; i < this.department.length; i++) {
        if (this.department[i].department_id === data.department_id) {
          //console.log("dd", data.department_id, this.department[i].department_name);
          if (status === "d") {
            if (parameter.download === true) {
              this.department[i].permission_data.view_only = true;
              this.department[i].permission_data.upload = false;
              this.department[i].permission_data.download = true;
              this.department[i].permission_data.edit = false;
              this.department[i].permission_data.delete = false;
            } else if (parameter.download === false) {
              this.department[i].permission_data.upload = false;
              this.department[i].permission_data.edit = false;
              this.department[i].permission_data.download = false;
              this.department[i].permission_data.view_only = true;
              this.department[i].permission_data.delete = false;
            }
          } else if (status === "v") {
            if (parameter.view_only === true) {
              this.department[i].permission_data.upload = false;
              this.department[i].permission_data.view_only = true;
              this.department[i].permission_data.download = false;
              this.department[i].permission_data.edit = false;
              this.department[i].permission_data.delete = false;
            } else if (parameter.view_only === false) {
              this.department[i].permission_data.download = false;
              this.department[i].permission_data.edit = false;
              this.department[i].permission_data.view_only = false;
              this.department[i].permission_data.delete = false;
              this.department[i].permission_data.upload = false;
            }
          } else if (status === "e") {
            // console.log("e");
            if (parameter.edit === true) {
              //  console.log("e", true);
              this.department[i].permission_data.upload = true;
              this.department[i].permission_data.view_only = true;
              this.department[i].permission_data.download = true;
              this.department[i].permission_data.edit = true;
              this.department[i].permission_data.delete = false;
              //  console.log("2",this.department[i].permission_data.view_only,this.department[i].department_name);
            } else if (parameter.edit === false) {
              // console.log("e", false);
              this.department[i].permission_data.upload = true;
              this.department[i].permission_data.download = true;
              this.department[i].permission_data.view_only = true;
              this.department[i].permission_data.edit = false;
              this.department[i].permission_data.delete = false;
            }
          } else if (status === "dl") {
            // console.log("a");
            if (parameter.delete === true) {
              //  console.log("b");
              this.department[i].permission_data.upload = true;
              this.department[i].permission_data.view_only = true;
              this.department[i].permission_data.download = true;
              this.department[i].permission_data.edit = true;
              this.department[i].permission_data.delete = true;
              //  console.log("2",this.department[i].permission_data.view_only,this.department[i].department_name);
            } else if (parameter.delete === false) {
              this.department[i].permission_data.upload = true;
              this.department[i].permission_data.download = true;
              this.department[i].permission_data.view_only = true;
              this.department[i].permission_data.edit = true;
              this.department[i].permission_data.delete = false;
            }
          } else if (status === "u") {
            //console.log("u");
            if (parameter.upload === true) {
              //  console.log("b");
              this.department[i].permission_data.upload = true;
              this.department[i].permission_data.view_only = true;
              this.department[i].permission_data.download = true;
              this.department[i].permission_data.edit = false;
              this.department[i].permission_data.delete = false;
              // console.log("2", this.department[i].permission_data.view_only, this.department[i].department_name);
            } else if (parameter.upload === false) {
              this.department[i].permission_data.upload = false;
              this.department[i].permission_data.download = true;
              this.department[i].permission_data.view_only = true;
              this.department[i].permission_data.edit = false;
              this.department[i].permission_data.delete = false;
            }
          }
        }
        //console.log("1", this.department[i].permission_data, this.department[i].staff_name_th);
      }
      //console.log(this.department);
      //console.log("setttttdepartmenttttttt", this.department);
    },
    setstatuspermissionrole(status, parameter, data) {
      console.log("this.role",this.role);
      for (let i = 0; i < this.role.length; i++) {
        if (this.role[i].role_id === data.role_id) {
          if (status === "d") {
            if (parameter.download === true) {
              this.role[i].permission_data.view_only = true;
              this.role[i].permission_data.upload = false;
              this.role[i].permission_data.download = true;
              this.role[i].permission_data.edit = false;
              this.role[i].permission_data.delete = false;
            } else if (parameter.download === false) {
              this.role[i].permission_data.upload = false;
              this.role[i].permission_data.edit = false;
              this.role[i].permission_data.download = false;
              this.role[i].permission_data.view_only = true;
              this.role[i].permission_data.delete = false;
            }
          } else if (status === "v") {
            if (parameter.view_only === true) {
              this.role[i].permission_data.upload = false;
              this.role[i].permission_data.view_only = true;
              this.role[i].permission_data.download = false;
              this.role[i].permission_data.edit = false;
              this.role[i].permission_data.delete = false;
            } else if (parameter.view_only === false) {
              this.role[i].permission_data.download = false;
              this.role[i].permission_data.edit = false;
              this.role[i].permission_data.view_only = false;
              this.role[i].permission_data.delete = false;
              this.role[i].permission_data.upload = false;
            }
          } else if (status === "e") {
            // console.log("e");
            if (parameter.edit === true) {
              //  console.log("e", true);
              this.role[i].permission_data.upload = true;
              this.role[i].permission_data.view_only = true;
              this.role[i].permission_data.download = true;
              this.role[i].permission_data.edit = true;
              this.role[i].permission_data.delete = false;
            } else if (parameter.edit === false) {
              // console.log("e", false);
              this.role[i].permission_data.upload = true;
              this.role[i].permission_data.download = true;
              this.role[i].permission_data.view_only = true;
              this.role[i].permission_data.edit = false;
              this.role[i].permission_data.delete = false;
            }
          } else if (status === "dl") {
            // console.log("a");
            if (parameter.delete === true) {
              //  console.log("b");
              this.role[i].permission_data.upload = true;
              this.role[i].permission_data.view_only = true;
              this.role[i].permission_data.download = true;
              this.role[i].permission_data.edit = true;
              this.role[i].permission_data.delete = true;
            } else if (parameter.delete === false) {
              this.role[i].permission_data.upload = true;
              this.role[i].permission_data.download = true;
              this.role[i].permission_data.view_only = true;
              this.role[i].permission_data.edit = true;
              this.role[i].permission_data.delete = false;
            }
          } else if (status === "u") {
            //console.log("u");
            if (parameter.upload === true) {
              //  console.log("b");
              this.role[i].permission_data.upload = true;
              this.role[i].permission_data.view_only = true;
              this.role[i].permission_data.download = true;
              this.role[i].permission_data.edit = false;
              this.role[i].permission_data.delete = false;
            } else if (parameter.upload === false) {
              this.role[i].permission_data.upload = false;
              this.role[i].permission_data.download = true;
              this.role[i].permission_data.view_only = true;
              this.role[i].permission_data.edit = false;
              this.role[i].permission_data.delete = false;
            }
          }
        }
      }
      console.log("setttttrole", this.role);
    },
    setstatuspermissionbusiness(status, data, parameter) {
      for (let i = 0; i < this.business.length; i++) {
        if (this.business[i].business_name === data.business_name) {
          //console.log("dd", data.department_id, this.department[i].department_name);
          if (status === "d") {
            if (parameter.download === true) {
              this.business[i].permission_business.view_only = true;
              this.business[i].permission_business.upload = false;
              this.business[i].permission_business.download = true;
              this.business[i].permission_business.edit = false;
              this.business[i].permission_business.delete = false;
            } else if (parameter.download === false) {
              this.business[i].permission_business.upload = false;
              this.business[i].permission_business.edit = false;
              this.business[i].permission_business.download = false;
              this.business[i].permission_business.view_only = true;
              this.business[i].permission_business.delete = false;
            }
          } else if (status === "v") {
            if (parameter.view_only === true) {
              this.business[i].permission_business.upload = false;
              this.business[i].permission_business.view_only = true;
              this.business[i].permission_business.download = false;
              this.business[i].permission_business.edit = false;
              this.business[i].permission_business.delete = false;
            } else if (parameter.view_only === false) {
              this.business[i].permission_business.download = false;
              this.business[i].permission_business.edit = false;
              this.business[i].permission_business.view_only = false;
              this.business[i].permission_business.delete = false;
              this.business[i].permission_business.upload = false;
            }
          } else if (status === "e") {
            //console.log("e");
            if (parameter.edit === true) {
              // console.log("e", true);
              this.business[i].permission_business.upload = true;
              this.business[i].permission_business.view_only = true;
              this.business[i].permission_business.download = true;
              this.business[i].permission_business.edit = true;
              this.business[i].permission_business.delete = false;
              //  console.log("2",this.department[i].permission_data.view_only,this.department[i].department_name);
            } else if (parameter.edit === false) {
              //console.log("e", false);
              this.business[i].permission_business.upload = true;
              this.business[i].permission_business.download = true;
              this.business[i].permission_business.view_only = true;
              this.business[i].permission_business.edit = false;
              this.business[i].permission_business.delete = false;
            }
          } else if (status === "dl") {
            // console.log("a");
            if (parameter.delete === true) {
              //  console.log("b");
              this.business[i].permission_business.upload = true;
              this.business[i].permission_business.view_only = true;
              this.business[i].permission_business.download = true;
              this.business[i].permission_business.edit = true;
              this.business[i].permission_business.delete = true;
              //  console.log("2",this.department[i].permission_data.view_only,this.department[i].department_name);
            } else if (parameter.delete === false) {
              this.business[i].permission_business.upload = true;
              this.business[i].permission_business.download = true;
              this.business[i].permission_business.view_only = true;
              this.business[i].permission_business.edit = true;
              this.business[i].permission_business.delete = false;
            }
          } else if (status === "u") {
            //console.log("u");
            if (parameter.upload === true) {
              //  console.log("b");
              this.business[i].permission_business.upload = true;
              this.business[i].permission_business.view_only = true;
              this.business[i].permission_business.download = true;
              this.business[i].permission_business.edit = false;
              this.business[i].permission_business.delete = false;
              //console.log("2",this.department[i].permission_data.view_only,this.department[i].department_name);
            } else if (parameter.upload === false) {
              this.business[i].permission_business.upload = false;
              this.business[i].permission_business.download = true;
              this.business[i].permission_business.view_only = true;
              this.business[i].permission_business.edit = false;
              this.business[i].permission_business.delete = false;
            }
          }
        }
        //console.log("1",this.business[i].permission_data,this.business[i].staff_name_th);
      }
    },
    async loadstaff() {
      //console.log("test");
      this.loaddataprogress = true;
      this.loaddataprogressstaff = true;
      this.permission_file = this.settoboolean(
        this.filedata.permission_setting ||
          this.filedata.file_permission_setting ||
          ""
      );
      this.permission_department_setting = this.settoboolean(
        this.filedata.permission_department_setting ||
          this.filedata.file_permission_department_setting ||
          ""
      );

      this.permission_role_setting = this.settoboolean(
        this.filedata.permission_role_setting ||
          this.filedata.permission_role_setting ||
          ""
      );
      this.permission_business = this.settoboolean(
        this.filedata.permission_business_setting ||
          this.filedata.file_permission_business_setting ||
          ""
      );
      //console.log("this.permission_business", this.permission_business, this.filedata);
      let staff = [];
      this.staff = [];
      this.Buff_staff = []; //เอาไว้ตอนเช็คsetpermission เทียบกันเพื่อหาตัวที่ตั้งค่า เพื่อส่งแค่ตัวที่ตั้งค่าให้ backend
      let buff_loop_staff = [];
      this.buff_loop_staff = []; //เอาไว้ฟิลเตอร์แผนกของพนักงาน
      this.filterdepartment = [];

      this.Buff_business = [];
      this.business = [];
      this.countemployee = 0;
      this.countdepartment = 0;

      let file_type = "";
      if (this.filedata.file_type === "folder") {
        file_type = "folder";
      } else {
        file_type = "file";
      }
      const formData = new FormData();
      formData.append(
        "business_id",
        this.dataAccountActive.business_info.business_id
      );
      formData.append("data_id", this.filedata.file_id);
      formData.append("data_type", this.filedata.file_type);
      formData.append("account_id", this.dataAccountId);
      formData.append("accesstoken", this.dataAccesstoken);
      formData.append("api_type", "setting_file_folder");

      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        data_id: this.filedata.file_id,
        data_type: this.filedata.file_type,
        account_id: this.dataAccountId,
        accesstoken: this.dataAccesstoken,
        api_type: "setting_file_folder",
        search: this.search_em === "" ? "" : this.search_em
      };
      //  console.log("p1",payload);
      const CancelToken = this.axios.CancelToken;
      const source = CancelToken.source();
      //console.log(this.checkCC);
      // if (this.checkCC === true) {
      //   source.cancel("Operation canceled by the user.");
      //   console.log(this.checkCC);
      //   this.checkCC = false;
      // }
      let auth = await gbfGenerate.generateToken();
      try {
        this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_MANAGE_ACCOUNT +
            "/api/v2/select_account_in_business",
          cancelToken: source.token,
          data: payload,
          headers: { Authorization: auth.code },
          onUploadProgress: (e) => {
            if (this.checkCC === true) {
              source.cancel("Operation canceled by the user.");
              //console.log(this.checkCC);
              this.checkCC = false;
            }
          },
        }).then((response) => {
          if (response.data.status === "OK") {
            this.checkRes = response.data.status;
            //console.log(response);
            for (const key in response.data.result) {
              let datafilter = {};

              let permission = {};
              datafilter["department_name"] = key;
              datafilter["department_id"] = key;

              this.filterdepartment.push(datafilter);
              //this.countemployee = response.data.result[key].length
              //////////////////สิทธพนักงานอันเก่า/////////////////////////
              // for (let i = 0; i < response.data.result[key].length; i++) {
              //   console.log("business_email_user",response.data.result[key][i]["business_email_user"]);
              //   let datastaff = {};
              //   datastaff["staff_id"] =
              //     response.data.result[key][i]["account_id"];
              //   datastaff["staff_name_en"] =
              //     response.data.result[key][i]["account_title_eng"] +
              //     " " +
              //     response.data.result[key][i]["first_name_eng"];
              //   // +
              //   // " " +
              //   // response.data.result[key][i]["last_name_eng"];
              //   datastaff["staff_name_th"] =
              //     response.data.result[key][i]["account_title_th"] +
              //     " " +
              //     response.data.result[key][i]["first_name_th"];
              //   // +
              //   // " " +
              //   // response.data.result[key][i]["last_name_th"];
              //   if (response.data.result[key][i]["permission_data"] === "") {
              //     datastaff["permission_data"] = {
              //       upload: false,
              //       view_only: false,
              //       download: false,
              //       edit: false,
              //       delete: false,
              //       admin_data: false,
              //     };
              //   } else {
              //     datastaff["permission_data"] = this.settoboolean(
              //       response.data.result[key][i]["permission_data"]
              //     );
              //   }
              //   datastaff["department_id"] =
              //     response.data.result[key][i]["department_id"];
              //   datastaff["department_name"] = key;
              //   datastaff["admin_folder"] = this.settoboolean(
              //     response.data.result[key][i]["permission_data"]["admin_data"]
              //   );
              //   if(response.data.result[key][i]["business_email_user"] === '' || response.data.result[key][i]["business_email_user"] === null || response.data.result[key][i]["business_email_user"] === undefined ){
              //     if(response.data.result[key][i]["email"] === '' || response.data.result[key][i]["email"] === null){
              //       datastaff["email"] = response.data.result[key][i]["thai_email"];
              //     }else{
              //       datastaff["email"] = response.data.result[key][i]["email"];
              //     }
              //   }else{
              //     datastaff["email"] = response.data.result[key][i]["business_email_user"];
              //   }
              //   //datadepartment["department_id"] = response.data.result[key][i]["department_id"];
              //   //console.log(datastaff);
              //   let databuffloopstaff = {};
              //   databuffloopstaff["staff_id"] =
              //     response.data.result[key][i]["account_id"];
              //   databuffloopstaff["staff_name_en"] =
              //     response.data.result[key][i]["account_title_eng"] +
              //     " " +
              //     response.data.result[key][i]["first_name_eng"];
              //   // +
              //   // " " +
              //   // response.data.result[key][i]["last_name_eng"];
              //   databuffloopstaff["staff_name_th"] =
              //     response.data.result[key][i]["account_title_th"] +
              //     " " +
              //     response.data.result[key][i]["first_name_th"];
              //   // + " " +
              //   // response.data.result[key][i]["last_name_th"];
              //   if (response.data.result[key][i]["permission_data"] === "") {
              //     databuffloopstaff["permission_data"] = {
              //       upload: false,
              //       view_only: false,
              //       download: false,
              //       edit: false,
              //       delete: false,
              //       admin_data: false,
              //     };
              //   } else {
              //     databuffloopstaff["permission_data"] = this.settoboolean(
              //       response.data.result[key][i]["permission_data"]
              //     );
              //   }
              //   databuffloopstaff["department_id"] =
              //     response.data.result[key][i]["department_id"];
              //   databuffloopstaff["department_name"] = key;
              //   databuffloopstaff["admin_folder"] = this.settoboolean(
              //     response.data.result[key][i]["permission_data"]["admin_data"]
              //   );
              //   if(response.data.result[key][i]["business_email_user"] === '' || response.data.result[key][i]["business_email_user"] === null || response.data.result[key][i]["business_email_user"] === undefined ){
              //     if(response.data.result[key][i]["email"] === '' || response.data.result[key][i]["email"] === null){
              //       databuffloopstaff["email"] = response.data.result[key][i]["thai_email"];
              //     }else{
              //       databuffloopstaff["email"] = response.data.result[key][i]["email"];
              //     }
              //   }else{
              //     databuffloopstaff["email"] = response.data.result[key][i]["business_email_user"];
              //   }
              //   databuffloopstaff["thai_email"] = response.data.result[key][i]["thai_email"];
              //   databuffloopstaff["business_email_user"] = response.data.result[key][i]["business_email_user"];
              //   databuffloopstaff["mobile"] = response.data.result[key][i]["mobile"];
              //   databuffloopstaff["username"] = response.data.result[key][i]["username"];
              //   //datadepartment["department_id"] = response.data.result[key][i]["department_id"];
              //   let databuffstaff = {};
              //   databuffstaff["staff_id"] =
              //     response.data.result[key][i]["account_id"];
              //   databuffstaff["staff_name_en"] =
              //     response.data.result[key][i]["account_title_eng"] +
              //     " " +
              //     response.data.result[key][i]["first_name_eng"];
              //   // +
              //   // " " +
              //   // response.data.result[key][i]["last_name_eng"];
              //   databuffstaff["staff_name_th"] =
              //     response.data.result[key][i]["account_title_th"] +
              //     " " +
              //     response.data.result[key][i]["first_name_th"];
              //   // +
              //   // " " +
              //   // response.data.result[key][i]["last_name_th"];
              //   if (response.data.result[key][i]["permission_data"] === "") {
              //     databuffstaff["permission_data"] = {
              //       upload: false,
              //       view_only: false,
              //       download: false,
              //       edit: false,
              //       delete: false,
              //       admin_data: false,
              //     };
              //   } else {
              //     databuffstaff["permission_data"] = this.settoboolean(
              //       response.data.result[key][i]["permission_data"]
              //     );
              //   }
              //   databuffstaff["department_id"] =
              //     response.data.result[key][i]["department_id"];
              //   databuffstaff["department_name"] = key;
              //   databuffstaff["admin_folder"] = this.settoboolean(
              //     response.data.result[key][i]["permission_data"]["admin_data"]
              //   );
              //   if(response.data.result[key][i]["business_email_user"] === '' || response.data.result[key][i]["business_email_user"] === null || response.data.result[key][i]["business_email_user"] === undefined ){
              //     if(response.data.result[key][i]["email"] === '' || response.data.result[key][i]["email"] === null){
              //       databuffstaff["email"] = response.data.result[key][i]["thai_email"];
              //     }else{
              //       databuffstaff["email"] = response.data.result[key][i]["email"];
              //     }
              //   }else{
              //     databuffstaff["email"] = response.data.result[key][i]["business_email_user"];
              //   }
              //   //datadepartment["department_id"] = response.data.result[key][i]["department_id"];
              //   this.Buff_staff.push(databuffstaff);
              //   buff_loop_staff.push(databuffloopstaff);
              //   staff.push(datastaff);
              //   this.countemployee = staff.length;
              //   // if (this.selectdepartment !== "") {
              //   //   if (this.selectdepartment === datastaff["department_name"]) {
              //   //     this.countemployee += 1;
              //   //     this.staff.push(datastaff);
              //   //   }
              //   // } else {
              //   //   this.countemployee += 1;
              //   //   this.staff.push(datastaff);
              //   // }
              // }

              // let f_staff_1 = staff.filter(
              //   (key) =>
              //     key.permission_data["upload"] === true ||
              //     key.permission_data["view_only"] === true ||
              //     key.permission_data["download"] === true ||
              //     key.permission_data["edit"] === true ||
              //     key.permission_data["delete"] === true ||
              //     key.permission_data["admin_data"] === true
              // );

              // let f_staff_2 = staff.filter(
              //   (key) =>
              //     key.permission_data["upload"] === false &&
              //     key.permission_data["view_only"] === false &&
              //     key.permission_data["download"] === false &&
              //     key.permission_data["edit"] === false &&
              //     key.permission_data["delete"] === false &&
              //     key.permission_data["admin_data"] === false
              // );

              // this.staff = f_staff_1.concat(f_staff_2);
              // let f_Buffloop_staff_1 = buff_loop_staff.filter(
              //   (key) =>
              //     key.permission_data["upload"] === true ||
              //     key.permission_data["view_only"] === true ||
              //     key.permission_data["download"] === true ||
              //     key.permission_data["edit"] === true ||
              //     key.permission_data["delete"] === true ||
              //     key.permission_data["admin_data"] === true
              // );

              // let f_Buffloop_staff_2 = buff_loop_staff.filter(
              //   (key) =>
              //     key.permission_data["upload"] === false &&
              //     key.permission_data["view_only"] === false &&
              //     key.permission_data["download"] === false &&
              //     key.permission_data["edit"] === false &&
              //     key.permission_data["delete"] === false &&
              //     key.permission_data["admin_data"] === false
              // );

              // this.buff_loop_staff = f_Buffloop_staff_1.concat(
              //   f_Buffloop_staff_2
              // );

              //console.log("sssdsdsdsdsdsdsdsdsd", this.staff);
              //console.log("this.Buff_staff", this.Buff_staff);
              //////////////////////////////////////////////////////
              
              this.department = [];
              this.Buff_role = [];
              this.Buff_department = [];
              let department = [];

              this.countdepartment = response.data.permission_department.length;
              for (
                let i = 0;
                i < response.data.permission_department.length;
                i++
              ) {
                let datadepartment = {};
                //if(response.data.permission_department[i].id === datadepartment["department_id"]){
                datadepartment["permission_data"] = this.settoboolean(
                  response.data.permission_department[i][
                    "permission_department"
                  ]
                );
                datadepartment["department_name"] =
                  response.data.permission_department[i]["department_name"];
                if (response.data.permission_department[i]["id"] === "") {
                  datadepartment["department_id"] = {
                    upload: false,
                    view_only: false,
                    download: false,
                    edit: false,
                    delete: false,
                  };
                } else {
                  datadepartment["department_id"] =
                    response.data.permission_department[i]["id"];
                }

                let databufferdepartment = {};
                //if(response.data.permission_department[i].id === datadepartment["department_id"]){
                databufferdepartment["permission_data"] = this.settoboolean(
                  response.data.permission_department[i][
                    "permission_department"
                  ]
                );
                databufferdepartment["department_name"] =
                  response.data.permission_department[i]["department_name"];
                if (response.data.permission_department[i]["id"] === "") {
                  databufferdepartment["department_id"] = {
                    upload: false,
                    view_only: false,
                    download: false,
                    edit: false,
                    delete: false,
                  };
                } else {
                  databufferdepartment["department_id"] =
                    response.data.permission_department[i]["id"];
                }
                department.push(datadepartment);
                this.Buff_department.push(databufferdepartment);
                //}
              }
              // console.log(department);
              let f_department_1 = department.filter(
                (key) =>
                  key.permission_data["upload"] === true ||
                  key.permission_data["view_only"] === true ||
                  key.permission_data["download"] === true ||
                  key.permission_data["edit"] === true ||
                  key.permission_data["delete"] === true
              );

              let f_department_2 = department.filter(
                (key) =>
                  key.permission_data["upload"] === false &&
                  key.permission_data["view_only"] === false &&
                  key.permission_data["download"] === false &&
                  key.permission_data["edit"] === false &&
                  key.permission_data["delete"] === false
              );
              // console.log(f_department_1,f_department_2);
              this.department = f_department_1.concat(f_department_2);
            }

            let defautdepartment = {
              department_name: "all department",
              department_id: "",
            };
            this.filterdepartment.push(defautdepartment);
            //console.log("e", this.staff);
            this.loaddataprogress = false;
            this.loaddataprogressstaff = false;
            //console.log("d", this.department);

            this.role = [];
            // this.Buff_department = [];
            let role_ = [];
            this.countrole = 0;
            for (let i = 0;i < response.data.permission_role.length;i++) {
                let datarole = {};
                  datarole["permission_data"] = this.settoboolean(response.data.permission_role[i]["permission_role"]);
                  datarole["role_name"] = response.data.permission_role[i]["role_name"];
                if (response.data.permission_role[i]["id"] === "") {
                  datarole["role_id"] = {
                    upload: false,
                    view_only: false,
                    download: false,
                    edit: false,
                    delete: false,
                  };
                } else {
                  datarole["role_id"] = response.data.permission_role[i]["id"];
                }

                let databufferderole = {};
                //if(response.data.permission_department[i].id === datadepartment["department_id"]){
                  databufferderole["permission_data"] = this.settoboolean(response.data.permission_role[i]["permission_role"]);
                  databufferderole["role_name"] = response.data.permission_role[i]["role_name"];
                  if (response.data.permission_role[i]["id"] === "") {
                    databufferderole["role_id"] = {
                      upload: false,
                      view_only: false,
                      download: false,
                      edit: false,
                      delete: false,
                    };
                  } else {
                    databufferderole["role_id"] = response.data.permission_role[i]["id"];
                  }
                this.role.push(datarole);
                role_.push(datarole);
                this.Buff_role.push(databufferderole);
              }
              this.countrole = response.data.permission_role.length;

            for (let i = 0; i < response.data.permission_business.length; i++) {
              let databusiness = {};
              //if(response.data.permission_department[i].id === datadepartment["department_id"]){
              if (
                response.data.permission_business[i]["permission_business"] ===
                ""
              ) {
                databusiness["permission_business"] = {
                  upload: false,
                  view_only: false,
                  download: false,
                  edit: false,
                  delete: false,
                };
              } else {
                databusiness["permission_business"] = this.settoboolean(
                  response.data.permission_business[i]["permission_business"]
                );
              }
              databusiness["business_name"] = this.dataAccountActive[
                "business_info"
              ]["first_name_th"];
              // datadepartment["department_id"] = response.data.permission_department[i]["id"];

              this.business.push(databusiness);
              this.Buff_business.push(databusiness);
              //}
            }

            //console.log("b", this.business);
          } else {
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + ": " + response.data.errorMessage,
            });
          }
        });
      } catch (err) {
        // check if the request was cancelled
        if (this.axios.isCancel(err)) {
          console.log(err.message);
        }
      }
    },
    async fn_reload() {
      //console.log("test");
      this.loaddataprogress = true;
      this.loaddataprogressstaff = true;
      this.permission_file = this.settoboolean(
        this.filedata.permission_setting ||
          this.filedata.file_permission_setting ||
          ""
      );
      this.permission_department_setting = this.settoboolean(
        this.filedata.permission_department_setting ||
          this.filedata.file_permission_department_setting ||
          ""
      );
      this.permission_business = this.settoboolean(
        this.filedata.permission_business_setting ||
          this.filedata.file_permission_business_setting ||
          ""
      );
      //console.log("this.permission_business", this.permission_business, this.filedata);
      let staff = [];
      this.staff = [];
      this.Buff_staff = []; //เอาไว้ตอนเช็คsetpermission เทียบกันเพื่อหาตัวที่ตั้งค่า เพื่อส่งแค่ตัวที่ตั้งค่าให้ backend
      let buff_loop_staff = [];
      this.buff_loop_staff = []; //เอาไว้ฟิลเตอร์แผนกของพนักงาน
      this.filterdepartment = [];

      this.Buff_business = [];
      this.business = [];
      this.countemployee = 0;
      this.countdepartment = 0;

      let file_type = "";
      if (this.filedata.file_type === "folder") {
        file_type = "folder";
      } else {
        file_type = "file";
      }
      const formData = new FormData();
      formData.append(
        "business_id",
        this.dataAccountActive.business_info.business_id
      );
      formData.append("data_id", this.filedata.file_id);
      formData.append("data_type", this.filedata.file_type);
      formData.append("account_id", this.dataAccountId);
      formData.append("accesstoken", this.dataAccesstoken);
      formData.append("api_type", "setting_file_folder");

      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        data_id: this.filedata.file_id,
        data_type: this.filedata.file_type,
        account_id: this.dataAccountId,
        accesstoken: this.dataAccesstoken,
        api_type: "setting_file_folder",
      };
      //  console.log("p1",payload);
      const CancelToken = this.axios.CancelToken;
      const source = CancelToken.source();
      //console.log(this.checkCC);
      // if (this.checkCC === true) {
      //   source.cancel("Operation canceled by the user.");
      //   console.log(this.checkCC);
      //   this.checkCC = false;
      // }
      let auth = await gbfGenerate.generateToken();
      try {
        this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_MANAGE_ACCOUNT +
            "/api/update_account_in_business",
          cancelToken: source.token,
          data: payload,
          headers: { Authorization: auth.code },
          onUploadProgress: (e) => {
            if (this.checkCC === true) {
              source.cancel("Operation canceled by the user.");
              //console.log(this.checkCC);
              this.checkCC = false;
            }
          },
        }).then((response) => {
          if (response.data.status === "OK") {
            this.checkRes = response.data.status;
            //console.log(response);
            for (const key in response.data.result) {
              let datafilter = {};

              let permission = {};
              datafilter["department_name"] = key;
              datafilter["department_id"] = key;

              this.filterdepartment.push(datafilter);
              //this.countemployee = response.data.result[key].length
              for (let i = 0; i < response.data.result[key].length; i++) {
                let datastaff = {};
                datastaff["staff_id"] =
                  response.data.result[key][i]["account_id"];
                datastaff["staff_name_en"] =
                  response.data.result[key][i]["account_title_eng"] +
                  " " +
                  response.data.result[key][i]["first_name_eng"];
                // +
                // " " +
                // response.data.result[key][i]["last_name_eng"];
                datastaff["staff_name_th"] =
                  response.data.result[key][i]["account_title_th"] +
                  " " +
                  response.data.result[key][i]["first_name_th"];
                // +
                // " " +
                // response.data.result[key][i]["last_name_th"];
                if (response.data.result[key][i]["permission_data"] === "") {
                  datastaff["permission_data"] = {
                    upload: false,
                    view_only: false,
                    download: false,
                    edit: false,
                    delete: false,
                    admin_data: false,
                  };
                } else {
                  datastaff["permission_data"] = this.settoboolean(
                    response.data.result[key][i]["permission_data"]
                  );
                }
                datastaff["department_id"] =
                  response.data.result[key][i]["department_id"];
                datastaff["department_name"] = key;
                datastaff["admin_folder"] = this.settoboolean(
                  response.data.result[key][i]["permission_data"]["admin_data"]
                );
                //datadepartment["department_id"] = response.data.result[key][i]["department_id"];
                //console.log(datastaff);
                let databuffloopstaff = {};
                databuffloopstaff["staff_id"] =
                  response.data.result[key][i]["account_id"];
                databuffloopstaff["staff_name_en"] =
                  response.data.result[key][i]["account_title_eng"] +
                  " " +
                  response.data.result[key][i]["first_name_eng"];
                // +
                // " " +
                // response.data.result[key][i]["last_name_eng"];
                databuffloopstaff["staff_name_th"] =
                  response.data.result[key][i]["account_title_th"] +
                  " " +
                  response.data.result[key][i]["first_name_th"];
                // +
                // " " +
                // response.data.result[key][i]["last_name_th"];
                if (response.data.result[key][i]["permission_data"] === "") {
                  databuffloopstaff["permission_data"] = {
                    upload: false,
                    view_only: false,
                    download: false,
                    edit: false,
                    delete: false,
                    admin_data: false,
                  };
                } else {
                  databuffloopstaff["permission_data"] = this.settoboolean(
                    response.data.result[key][i]["permission_data"]
                  );
                }
                databuffloopstaff["department_id"] =
                  response.data.result[key][i]["department_id"];
                databuffloopstaff["department_name"] = key;
                databuffloopstaff["admin_folder"] = this.settoboolean(
                  response.data.result[key][i]["permission_data"]["admin_data"]
                );
                //datadepartment["department_id"] = response.data.result[key][i]["department_id"];
                let databuffstaff = {};
                databuffstaff["staff_id"] =
                  response.data.result[key][i]["account_id"];
                databuffstaff["staff_name_en"] =
                  response.data.result[key][i]["account_title_eng"] +
                  " " +
                  response.data.result[key][i]["first_name_eng"];
                // +
                // " " +
                // response.data.result[key][i]["last_name_eng"];
                databuffstaff["staff_name_th"] =
                  response.data.result[key][i]["account_title_th"] +
                  " " +
                  response.data.result[key][i]["first_name_th"];
                // +
                // " " +
                // response.data.result[key][i]["last_name_th"];
                if (response.data.result[key][i]["permission_data"] === "") {
                  databuffstaff["permission_data"] = {
                    upload: false,
                    view_only: false,
                    download: false,
                    edit: false,
                    delete: false,
                    admin_data: false,
                  };
                } else {
                  databuffstaff["permission_data"] = this.settoboolean(
                    response.data.result[key][i]["permission_data"]
                  );
                }
                databuffstaff["department_id"] =
                  response.data.result[key][i]["department_id"];
                databuffstaff["department_name"] = key;
                databuffstaff["admin_folder"] = this.settoboolean(
                  response.data.result[key][i]["permission_data"]["admin_data"]
                );
                //datadepartment["department_id"] = response.data.result[key][i]["department_id"];
                this.Buff_staff.push(databuffstaff);
                buff_loop_staff.push(databuffloopstaff);
                staff.push(datastaff);
                this.countemployee = staff.length;
                // if (this.selectdepartment !== "") {
                //   if (this.selectdepartment === datastaff["department_name"]) {
                //     this.countemployee += 1;
                //     this.staff.push(datastaff);
                //   }
                // } else {
                //   this.countemployee += 1;
                //   this.staff.push(datastaff);
                // }
              }

              let f_staff_1 = staff.filter(
                (key) =>
                  key.permission_data["upload"] === true ||
                  key.permission_data["view_only"] === true ||
                  key.permission_data["download"] === true ||
                  key.permission_data["edit"] === true ||
                  key.permission_data["delete"] === true ||
                  key.permission_data["admin_data"] === true
              );

              let f_staff_2 = staff.filter(
                (key) =>
                  key.permission_data["upload"] === false &&
                  key.permission_data["view_only"] === false &&
                  key.permission_data["download"] === false &&
                  key.permission_data["edit"] === false &&
                  key.permission_data["delete"] === false &&
                  key.permission_data["admin_data"] === false
              );

              this.staff = f_staff_1.concat(f_staff_2);

              let f_Buffloop_staff_1 = buff_loop_staff.filter(
                (key) =>
                  key.permission_data["upload"] === true ||
                  key.permission_data["view_only"] === true ||
                  key.permission_data["download"] === true ||
                  key.permission_data["edit"] === true ||
                  key.permission_data["delete"] === true ||
                  key.permission_data["admin_data"] === true
              );

              let f_Buffloop_staff_2 = buff_loop_staff.filter(
                (key) =>
                  key.permission_data["upload"] === false &&
                  key.permission_data["view_only"] === false &&
                  key.permission_data["download"] === false &&
                  key.permission_data["edit"] === false &&
                  key.permission_data["delete"] === false &&
                  key.permission_data["admin_data"] === false
              );

              this.buff_loop_staff = f_Buffloop_staff_1.concat(
                f_Buffloop_staff_2
              );

              //console.log("sssdsdsdsdsdsdsdsdsd", this.staff);
              //console.log("this.Buff_staff", this.Buff_staff);
              this.department = [];
              this.Buff_department = [];
              let department = [];

              this.countdepartment = response.data.permission_department.length;
              for (
                let i = 0;
                i < response.data.permission_department.length;
                i++
              ) {
                let datadepartment = {};
                //if(response.data.permission_department[i].id === datadepartment["department_id"]){
                datadepartment["permission_data"] = this.settoboolean(
                  response.data.permission_department[i][
                    "permission_department"
                  ]
                );
                datadepartment["department_name"] =
                  response.data.permission_department[i]["department_name"];
                if (response.data.permission_department[i]["id"] === "") {
                  datadepartment["department_id"] = {
                    upload: false,
                    view_only: false,
                    download: false,
                    edit: false,
                    delete: false,
                  };
                } else {
                  datadepartment["department_id"] =
                    response.data.permission_department[i]["id"];
                }

                let databufferdepartment = {};
                //if(response.data.permission_department[i].id === datadepartment["department_id"]){
                databufferdepartment["permission_data"] = this.settoboolean(
                  response.data.permission_department[i][
                    "permission_department"
                  ]
                );
                databufferdepartment["department_name"] =
                  response.data.permission_department[i]["department_name"];
                if (response.data.permission_department[i]["id"] === "") {
                  databufferdepartment["department_id"] = {
                    upload: false,
                    view_only: false,
                    download: false,
                    edit: false,
                    delete: false,
                  };
                } else {
                  databufferdepartment["department_id"] =
                    response.data.permission_department[i]["id"];
                }
                department.push(datadepartment);
                this.Buff_department.push(databufferdepartment);
                //}
              }
              // console.log(department);
              let f_department_1 = department.filter(
                (key) =>
                  key.permission_data["upload"] === true ||
                  key.permission_data["view_only"] === true ||
                  key.permission_data["download"] === true ||
                  key.permission_data["edit"] === true ||
                  key.permission_data["delete"] === true
              );

              let f_department_2 = department.filter(
                (key) =>
                  key.permission_data["upload"] === false &&
                  key.permission_data["view_only"] === false &&
                  key.permission_data["download"] === false &&
                  key.permission_data["edit"] === false &&
                  key.permission_data["delete"] === false
              );
              // console.log(f_department_1,f_department_2);
              this.department = f_department_1.concat(f_department_2);
            }

            let defautdepartment = {
              department_name: "all department",
              department_id: "",
            };
            this.filterdepartment.push(defautdepartment);
            //console.log("e", this.staff);
            this.loaddataprogress = false;
            this.loaddataprogressstaff = false;
            //console.log("d", this.department);

            for (let i = 0; i < response.data.permission_business.length; i++) {
              let databusiness = {};
              //if(response.data.permission_department[i].id === datadepartment["department_id"]){
              if (
                response.data.permission_business[i]["permission_business"] ===
                ""
              ) {
                databusiness["permission_business"] = {
                  upload: false,
                  view_only: false,
                  download: false,
                  edit: false,
                  delete: false,
                };
              } else {
                databusiness["permission_business"] = this.settoboolean(
                  response.data.permission_business[i]["permission_business"]
                );
              }
              databusiness["business_name"] = this.dataAccountActive[
                "business_info"
              ]["first_name_th"];
              // datadepartment["department_id"] = response.data.permission_department[i]["id"];

              this.business.push(databusiness);
              this.Buff_business.push(databusiness);
              //}
            }
            //console.log("b", this.business);
          } else {
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + ": " + response.data.errorMessage,
            });
          }
        });
      } catch (err) {
        // check if the request was cancelled
        if (this.axios.isCancel(err)) {
          console.log(err.message);
        }
      }
    },
    async fn_reload_v2 () {
      // ของเก่าา
      
      this.loaddataprogress = true;
      this.loaddataprogressstaff = true;
      this.permission_file = this.settoboolean(
        this.filedata.permission_setting ||
          this.filedata.file_permission_setting ||
          ""
      );
      this.permission_department_setting = this.settoboolean(
        this.filedata.permission_department_setting ||
          this.filedata.file_permission_department_setting ||
          ""
      );
      this.permission_role_setting = this.settoboolean(
        this.filedata.permission_role_setting ||
          this.filedata.permission_role_setting ||
          ""
      );
      this.permission_business = this.settoboolean(
        this.filedata.permission_business_setting ||
          this.filedata.file_permission_business_setting ||
          ""
      );
      // แค่ของ Staff
      let staff = [];
      this.staff = [];
      this.Buff_staff = []; //เอาไว้ตอนเช็คsetpermission เทียบกันเพื่อหาตัวที่ตั้งค่า เพื่อส่งแค่ตัวที่ตั้งค่าให้ backend
      let buff_loop_staff = [];
      this.buff_loop_staff = []; //เอาไว้ฟิลเตอร์แผนกของพนักงาน
      this.chipstaff = this.permission_file ? this.color.theme : "blue-grey lighten-3";

      this.data_permission_business["permision_status"] =  this.filedata["permission_business_setting"] === "True" ? true : false   // BUSINESS 
      this.data_permission_department["permision_status"] =  this.filedata["permission_department_setting"] === "True" ? true : false  // Department 
      this.data_permission_role["permision_status"] =  this.filedata["permission_role_setting"] === "True" ? true : false  // role 
      this.data_permission_employees["permision_status"] =  this.filedata["permission_setting"] === "True" ? true : false  // emplayees
      // Department
      var payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        data_id: this.filedata.file_id,
        data_type: this.filedata.file_type,
        account_id: this.dataAccountId,
        accesstoken: this.dataAccesstoken,
        api_type: "setting_file_folder",
        search: this.search_em === "" ? "" : this.search_em
      };
      let auth = await gbfGenerate.generateToken();
      try{
        this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_MANAGE_ACCOUNT +
            "/api/v2/select_account_in_business",
          data: payload,
          headers: { Authorization: auth.code },
        })
        .then((response) => {
            if (response.data.status === "OK") {
              console.log("reponse permsiio ", response)
              // Business
              this.data_permission_business['permission_active'] =  this.convert_bool_permission (response.data.permission_business[0]['permission_business'])
              this.data_permission_business['permission_number'] = response.data.permission_business[0]['permission_business_v2']
              // Employee
              this.data_permission_department['permission_value'] = response.data.permission_department
              this.data_permission_department['permission_value'].forEach((item) => {
                item['permission_active'] = this.convert_bool_permission (item['permission_department'])  
                delete item['permission_department']
              })
              // Role 
              this.data_permission_role['permission_value'] = response.data.permission_role
              this.data_permission_role['permission_value'].forEach((item) => {
                item['permission_active'] = this.convert_bool_permission (item['permission_role'])  
                delete item['permission_role']
              })

              this.countdepartment = this.data_permission_department['permission_value'].length
              this.countrole = this.data_permission_role['permission_value'].length

              // STAFF ของเก่า
              for (const key in response.data.result) {
                for (let i = 0; i < response.data.result[key].length; i++) {
                  let datastaff = {};
                  datastaff["staff_id"] =
                    response.data.result[key][i]["account_id"];
                  datastaff["staff_name_en"] =
                    response.data.result[key][i]["account_title_eng"] +
                    " " +
                    response.data.result[key][i]["first_name_eng"];
                  datastaff["staff_name_th"] =
                    response.data.result[key][i]["account_title_th"] +
                    " " +
                    response.data.result[key][i]["first_name_th"];
                  if (response.data.result[key][i]["permission_data"] === "") {
                    datastaff["permission_data"] = {
                      upload: false,
                      view_only: false,
                      download: false,
                      edit: false,
                      delete: false,
                      admin_data: false,
                    };
                  } else {
                    datastaff["permission_data"] = this.settoboolean(
                      response.data.result[key][i]["permission_data"]
                    );
                  }
                  datastaff["department_id"] =
                    response.data.result[key][i]["department_id"];
                  datastaff["department_name"] = key;
                  datastaff["admin_folder"] = this.settoboolean(
                    response.data.result[key][i]["permission_data"]["admin_data"]
                  );
                  if(response.data.result[key][i]["business_email_user"] === '' || response.data.result[key][i]["business_email_user"] === null || response.data.result[key][i]["business_email_user"] === undefined ){
                    if(response.data.result[key][i]["email"] === '' || response.data.result[key][i]["email"] === null){
                      datastaff["email"] = response.data.result[key][i]["thai_email"];
                    }else{
                      datastaff["email"] = response.data.result[key][i]["email"];
                    }
                  }else{
                    datastaff["email"] = response.data.result[key][i]["business_email_user"];
                  }
                  //datadepartment["department_id"] = response.data.result[key][i]["department_id"];
                  //console.log(datastaff);
                  let databuffloopstaff = {};
                  databuffloopstaff["staff_id"] =
                    response.data.result[key][i]["account_id"];
                  databuffloopstaff["staff_name_en"] =
                    response.data.result[key][i]["account_title_eng"] +
                    " " +
                    response.data.result[key][i]["first_name_eng"];
                  // +
                  // " " +
                  // response.data.result[key][i]["last_name_eng"];
                  databuffloopstaff["staff_name_th"] =
                    response.data.result[key][i]["account_title_th"] +
                    " " +
                    response.data.result[key][i]["first_name_th"];
                  // + " " +
                  // response.data.result[key][i]["last_name_th"];
                  if (response.data.result[key][i]["permission_data"] === "") {
                    databuffloopstaff["permission_data"] = {
                      upload: false,
                      view_only: false,
                      download: false,
                      edit: false,
                      delete: false,
                      admin_data: false,
                    };
                  } else {
                    databuffloopstaff["permission_data"] = this.settoboolean(
                      response.data.result[key][i]["permission_data"]
                    );
                  }
                  databuffloopstaff["department_id"] =
                    response.data.result[key][i]["department_id"];
                  databuffloopstaff["department_name"] = key;
                  databuffloopstaff["admin_folder"] = this.settoboolean(
                    response.data.result[key][i]["permission_data"]["admin_data"]
                  );
                  if(response.data.result[key][i]["business_email_user"] === '' || response.data.result[key][i]["business_email_user"] === null || response.data.result[key][i]["business_email_user"] === undefined ){
                    if(response.data.result[key][i]["email"] === '' || response.data.result[key][i]["email"] === null){
                      databuffloopstaff["email"] = response.data.result[key][i]["thai_email"];
                    }else{
                      databuffloopstaff["email"] = response.data.result[key][i]["email"];
                    }
                  }else{
                    databuffloopstaff["email"] = response.data.result[key][i]["business_email_user"];
                  }
                  databuffloopstaff["thai_email"] = response.data.result[key][i]["thai_email"];
                  databuffloopstaff["business_email_user"] = response.data.result[key][i]["business_email_user"];
                  databuffloopstaff["mobile"] = response.data.result[key][i]["mobile"];
                  databuffloopstaff["username"] = response.data.result[key][i]["username"];
                  //datadepartment["department_id"] = response.data.result[key][i]["department_id"];
                  let databuffstaff = {};
                  databuffstaff["staff_id"] =
                    response.data.result[key][i]["account_id"];
                  databuffstaff["staff_name_en"] =
                    response.data.result[key][i]["account_title_eng"] +
                    " " +
                    response.data.result[key][i]["first_name_eng"];
                  // +
                  // " " +
                  // response.data.result[key][i]["last_name_eng"];
                  databuffstaff["staff_name_th"] =
                    response.data.result[key][i]["account_title_th"] +
                    " " +
                    response.data.result[key][i]["first_name_th"];
                  // +
                  // " " +
                  // response.data.result[key][i]["last_name_th"];
                  if (response.data.result[key][i]["permission_data"] === "") {
                    databuffstaff["permission_data"] = {
                      upload: false,
                      view_only: false,
                      download: false,
                      edit: false,
                      delete: false,
                      admin_data: false,
                    };
                  } else {
                    databuffstaff["permission_data"] = this.settoboolean(
                      response.data.result[key][i]["permission_data"]
                    );
                  }
                  databuffstaff["department_id"] =
                    response.data.result[key][i]["department_id"];
                  databuffstaff["department_name"] = key;
                  databuffstaff["admin_folder"] = this.settoboolean(
                    response.data.result[key][i]["permission_data"]["admin_data"]
                  );
                  if(response.data.result[key][i]["business_email_user"] === '' || response.data.result[key][i]["business_email_user"] === null || response.data.result[key][i]["business_email_user"] === undefined ){
                    if(response.data.result[key][i]["email"] === '' || response.data.result[key][i]["email"] === null){
                      databuffstaff["email"] = response.data.result[key][i]["thai_email"];
                    }else{
                      databuffstaff["email"] = response.data.result[key][i]["email"];
                    }
                  }else{
                    databuffstaff["email"] = response.data.result[key][i]["business_email_user"];
                  }
                  //datadepartment["department_id"] = response.data.result[key][i]["department_id"];
                  this.Buff_staff.push(databuffstaff);
                  buff_loop_staff.push(databuffloopstaff);
                  staff.push(datastaff);
                  this.countemployee = staff.length;
                
                }

                let f_staff_1 = staff.filter(
                  (key) =>
                    key.permission_data["upload"] === true ||
                    key.permission_data["view_only"] === true ||
                    key.permission_data["download"] === true ||
                    key.permission_data["edit"] === true ||
                    key.permission_data["delete"] === true ||
                    key.permission_data["admin_data"] === true
                );
                let f_staff_2 = staff.filter(
                  (key) =>
                    key.permission_data["upload"] === false &&
                    key.permission_data["view_only"] === false &&
                    key.permission_data["download"] === false &&
                    key.permission_data["edit"] === false &&
                    key.permission_data["delete"] === false &&
                    key.permission_data["admin_data"] === false
                );
                this.staff = f_staff_1.concat(f_staff_2);
                let f_Buffloop_staff_1 = buff_loop_staff.filter(
                  (key) =>
                    key.permission_data["upload"] === true ||
                    key.permission_data["view_only"] === true ||
                    key.permission_data["download"] === true ||
                    key.permission_data["edit"] === true ||
                    key.permission_data["delete"] === true ||
                    key.permission_data["admin_data"] === true
                );
                let f_Buffloop_staff_2 = buff_loop_staff.filter(
                  (key) =>
                    key.permission_data["upload"] === false &&
                    key.permission_data["view_only"] === false &&
                    key.permission_data["download"] === false &&
                    key.permission_data["edit"] === false &&
                    key.permission_data["delete"] === false &&
                    key.permission_data["admin_data"] === false
                );
                this.buff_loop_staff = f_Buffloop_staff_1.concat(
                  f_Buffloop_staff_2
                );
                

              
            }



              //  SET BACKUP FOR COMPARING 
              this.backup_compare_business = JSON.stringify(this.data_permission_business)
              this.backup_compare_department = JSON.stringify(this.data_permission_department)
              this.backup_compare_role = JSON.stringify(this.data_permission_role)
              this.backup_compare_staff = JSON.stringify(this.buff_loop_staff)
              this.backup_compar_header_staff = JSON.stringify(this.data_permission_employees.permision_status)
              console.log('role permission ', this.buff_loop_staff)
              this.loaddataprogress = false
              this.loaddataprogressstaff = false
            } else {

            }
        })
        .catch((err) => {
          console.log("Error something", err)
        })
      } catch (err) {
        console.log('Erorr ', err)
      }

    },
    getFile(fileEntry) {
      try {
        return new Promise((resolve, reject) =>
          fileEntry.file(resolve, reject)
        );
      } catch (err) {
        console.log(err);
      }
    },
    settoboolean(parameter) {
      //console.log("parameter", parameter);
      let newparameter = {};
      if (parameter === "True" || parameter === "False" || parameter === "") {
        if (parameter === "True") {
          return true;
        } else if (parameter === "False") {
          return false;
        } else {
          return "";
        }
      } else {
        if (parameter.delete === "True") {
          newparameter["delete"] = true;
        } else if (parameter.delete === "False") {
          newparameter["delete"] = false;
        }

        if (parameter.upload === "True") {
          newparameter["upload"] = true;
        } else if (parameter.delete === "False") {
          newparameter["upload"] = false;
        }

        if (parameter.view_only === "True") {
          //  console.log("dd",parameter.view);
          newparameter["view_only"] = true;
        } else if (parameter.view_only === "False") {
          newparameter["view_only"] = false;
        }

        if (parameter.download === "True") {
          newparameter["download"] = true;
        } else if (parameter.download === "False") {
          newparameter["download"] = false;
        }

        if (parameter.edit === "True") {
          newparameter["edit"] = true;
        } else if (parameter.edit === "False") {
          newparameter["edit"] = false;
        }

        if (parameter.admin_data === "True") {
          newparameter["admin_data"] = true;
        } else if (parameter.admin_data === "False") {
          newparameter["admin_data"] = false;
        }
      }
      //console.log(newparameter);

      return newparameter;
    },
    settostring(parameter) {
      let newparameter = {};
      if (parameter === true || parameter === false) {
        if (parameter === true) {
          return "True";
        } else if (parameter === false) {
          return "False";
        }
      } else {
        if (parameter.view_only === true) {
          newparameter["view_only"] = "True";
        } else if (parameter.view_only === false) {
          newparameter["view_only"] = "False";
        }

        if (parameter.upload === true) {
          // console.log("dd",parameter.view);
          newparameter["upload"] = "True";
        } else if (parameter.upload === false) {
          newparameter["upload"] = "False";
        }

        if (parameter.download === true) {
          newparameter["download"] = "True";
        } else if (parameter.download === false) {
          newparameter["download"] = "False";
        }

        if (parameter.delete === true) {
          newparameter["delete"] = "True";
        } else if (parameter.delete === false) {
          newparameter["delete"] = "False";
        }

        if (parameter.edit === true) {
          newparameter["edit"] = "True";
        } else if (parameter.edit === false) {
          newparameter["edit"] = "False";
        }

        if (parameter.admin_data === true) {
          newparameter["admin_data"] = "True";
        } else if (parameter.admin_data === false) {
          newparameter["admin_data"] = "False";
        }
      }

      //console.log(newparameter);

      return newparameter;
    },
    cancel() {
      //console.log("this.checkRes", this.checkRes);
      if (this.checkRes === "") {
        this.checkCC = true;
      }

      this.closesetpermission();
    },
    closesetpermission() {
      this.test = 1;
      this.$emit("closesetPermissionFile");
      this.selectdepartment = "";
      this.select_staff = [];
      this.all_setting = false;
      this.labelallsetting = "เปิดทั้งหมด";
      this.check_api_permission = [
        {id: 1, name: 'business', use_api: false},
        {id: 2, name: 'department', use_api: false},
        {id: 3, name: 'role', use_api: false},
        {id: 4, name: 'employee', use_api: false}
      ]
      this.permission_clone = false
      //console.log(this.checkCC);
    },

    async setpermissionfile() {
      this.loaddataprogress = true;
      this.loaddataprogressstaff = true;
      var payload;
      var payloaddepartment;
      var payloadrole;
      //console.log("stafffffffffffffffffffffffffff", this.staff, this.Buff_staff, this.buff_loop_staff);
      let allow_account = [];
      let item_loop = [];
      // this.buff_filter_staff = this.buff_loop_staff.filter((department) => department.department_name === this.selectdepartment);
      // console.log("this.buff_filter_staff", this.buff_filter_staff);
      // if (this.staff.length !== this.buff_filter_staff.length) {
      //   item_loop = this.buff_filter_staff;
      // } else {
      //   item_loop = this.staff;
      // }
      // console.log("item_loop", item_loop, this.buff_filter_staff);
      //this.Buff_staff = this.buff_filter_staff
      //console.log(this.Buff_staff, this.staff);
      //this.staff = this.Buff_staff
      //console.log("allow_account",allow_account);
      // let raw_buff_staff = this.Buff_staff.filter(
      //   (department) => department.department_name === this.selectdepartment
      // );
      // if (this.selectdepartment === "") {
      //   raw_buff_staff = this.Buff_staff;
      // }
      let raw_buff_staff = this.Buff_staff;
      for (let j = 0; j < this.buff_loop_staff.length; j++) {
      // for (let j = 0; j < this.staff.length; j++) {
        //for (let i = 0; i < raw_buff_staff.length; i++) {
        // for(let j = 0; j<this.staff.length;j++){
        //console.log("test");
        let dataallow = {};
        //console.log("v"+i,this.staff[i].permission_data !== this.Buff_staff[i].permission_data);
        //console.log(this.staff,this.Buff_staff,this.staff[i].permission_data);
        let obj = raw_buff_staff.findIndex(
          (ab) => ab.staff_id === this.buff_loop_staff[j].staff_id
        );
        if (obj !== -1) {
          if (
            this.buff_loop_staff[j].permission_data.view_only !==
              raw_buff_staff[obj].permission_data.view_only ||
            this.buff_loop_staff[j].permission_data.download !==
              raw_buff_staff[obj].permission_data.download ||
            this.buff_loop_staff[j].permission_data.upload !==
              raw_buff_staff[obj].permission_data.upload ||
            this.buff_loop_staff[j].permission_data.edit !==
              raw_buff_staff[obj].permission_data.edit ||
            this.buff_loop_staff[j].permission_data.delete !==
              raw_buff_staff[obj].permission_data.delete ||
            this.buff_loop_staff[j].permission_data.admin_data !==
              raw_buff_staff[obj].permission_data.admin_data
          ) {
            dataallow[this.buff_loop_staff[j].staff_id] = this.settostring(
              this.buff_loop_staff[j].permission_data
            );
            //console.log("iffffff",dataallow);
            allow_account.push(dataallow);
            //console.log("yy", allow_account);
          } else {
            continue;
          }
        } else {
          continue;
        }
        // }
      }
      //}

      if (allow_account === []) {
        this.checkregS = true;
      }
      let file_type = "";
      if (this.filedata.file_type === "folder") {
        file_type = "folder";
      } else {
        file_type = "file";
      }
      payload = {
        account_id: this.dataAccountId,
        allow_account: allow_account,
        data_id: this.filedata.file_id,
        data_type: file_type,
        clone: this.permission_clone === true ? "True" : "False",
        status_setting: this.settostring(this.permission_file),
      };
      //console.log("payloadstaff", payload);
      //console.log("depart", this.department);

      let allow_department = [];
      for (let i = 0; i < this.Buff_department.length; i++) {
        //console.log("test");
        let dataallow = {};
        // if(this.department[i].permission_data.edit === true){
        //   this.department[i].permission_data.view_only = true
        //   this.department[i].permission_data.download = true
        // }else if(this.department[i].permission_data.download === true){
        //   this.department[i].permission_data.view_only = true
        // }
        let obj = this.department.findIndex(
          (ab) => ab.department_id === this.Buff_department[i].department_id
        );
        //console.log("obj",obj);
        if (obj !== -1) {
          if (
            this.department[obj].permission_data.view_only !==
              this.Buff_department[i].permission_data.view_only ||
            this.department[obj].permission_data.download !==
              this.Buff_department[i].permission_data.download ||
            this.department[obj].permission_data.upload !==
              this.Buff_department[i].permission_data.upload ||
            this.department[obj].permission_data.edit !==
              this.Buff_department[i].permission_data.edit ||
            this.department[obj].permission_data.delete !==
              this.Buff_department[i].permission_data.delete
          ) {
            dataallow[this.department[obj].department_id] = this.settostring(
              this.department[obj].permission_data
            );
            //console.log("dataallow", dataallow);
            allow_department.push(dataallow);
          } else {
            continue;
          }
        } else {
          continue;
        }
      }
      // console.log("allow_department", allow_department);
      if (allow_department === []) {
        this.checkregD = true;
      }
      payloaddepartment = {
        account_id: this.dataAccountId,
        allow_department: allow_department,
        data_id: this.filedata.file_id,
        data_type: file_type,
        clone: this.permission_clone === true ? "True" : "False",
        status_setting: this.settostring(this.permission_department_setting),
      };
      console.log("payloaddepart", payloaddepartment);

      let allow_business = [];
      //  for(let i = 0; i<this.business.length;i++){
      //  let dataallow = {};
      // // if(this.department[i].permission_data.edit === true){
      // //   this.department[i].permission_data.view_only = true
      // //   this.department[i].permission_data.download = true
      // // }else if(this.department[i].permission_data.download === true){
      // //   this.department[i].permission_data.view_only = true
      // // }
      // dataallow[this.department[i].department_id]=this.settostring(this.department[i].permission_data);
      // console.log(dataallow);
      // allow_depaallow_businessrtment.push(dataallow);
      // }
      //console.log("this.business.permission_business", this.business[0].permission_business, this.business);
      var payloadbusiness = {
        data_id: this.filedata.file_id,
        data_type: file_type,
        account_id: this.dataAccountId,
        clone: this.permission_clone === true ? "True" : "False",
        status_setting: this.settostring(this.permission_business),
        permission_business: this.settostring(
          this.business[0].permission_business
        ),
      };


      let allow_role = [];
      for (let i = 0; i < this.Buff_role.length; i++) {
        //console.log("test");
        let dataallow = {};
        let obj = this.role.findIndex(
          (ab) => ab.role_id === this.Buff_role[i].role_id
        );
        //console.log("obj",obj);
        if (obj !== -1) {
          if (
            this.role[obj].permission_data.view_only !==
              this.Buff_role[i].permission_data.view_only ||
            this.role[obj].permission_data.download !==
              this.Buff_role[i].permission_data.download ||
            this.role[obj].permission_data.upload !==
              this.Buff_role[i].permission_data.upload ||
            this.role[obj].permission_data.edit !==
              this.Buff_role[i].permission_data.edit ||
            this.role[obj].permission_data.delete !==
              this.Buff_role[i].permission_data.delete
          ) {
            dataallow[this.role[obj].role_id] = this.settostring(
              this.role[obj].permission_data
            );
            //console.log("dataallow", dataallow);
            allow_role.push(dataallow);
          } else {
            continue;
          }
        } else {
          continue;
        }
      }
      // console.log("allow_department", allow_department);
      if (allow_role === []) {
        this.checkregD = true;
      }
      var payloadrole = {
        account_id: this.dataAccountId,
        allow_role: allow_role,
        data_id: this.filedata.file_id,
        data_type: file_type,
        clone: this.permission_clone === true ? "True" : "False",
        status_setting: this.settostring(this.permission_role_setting),
      }
      //console.log("payloadbusiness", payloadbusiness);

      try {
        let res_1;
        let res_2;
        let res_3;
        //  if(allow_account.length === 0){
        //     res_1 = 'OK'
        //     //console.log("leg1",req1);
        //  }else{
        let auth = await gbfGenerate.generateToken();
        let req1 = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES +
            "/api/setting_data_permission",
          data: payload,
          headers: { Authorization: auth.code },
        });
        res_1 = req1.data.status;
        // }
        //  if(allow_department.length === 0){

        //     res_2= 'OK'

        //  }else{
        let auth1 = await gbfGenerate.generateToken();
        let req2 = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES +
            "/api/setting_department_permission",
          data: payloaddepartment,
          headers: { Authorization: auth1.code },
        });
        res_2 = req2.data.status;
        //  }
        let auth2 = await gbfGenerate.generateToken();
        let req3 = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES +
            "/api/setting_business_permission",
          data: payloadbusiness,
          headers: { Authorization: auth2.code },
        });


        let auth3 = await gbfGenerate.generateToken();
        let req4 = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES +
            "/api/setting_data_permission_role",
          data: payloadrole,
          headers: { Authorization: auth3.code },
        });
        res_3 = req4.data.status;
        

        if (res_1 === "OK" && res_2 === "OK" && req3.data.status === "OK" && res_3 === "OK" ) {
          this.loaddataprogress = false;
          this.loaddataprogressstaff = false;
          //this.$emit('closesetPermissionFile')
          Toast.fire({
            icon: "success",
            title: this.$t("admintoast.settingsuccess"),
          });
          //this.$emit('closesetPermissionFile')
          this.test = 1;
          this.selectdepartment = "";
        } else {
          this.loaddataprogress = false;
          this.loaddataprogressstaff = false;
          if (res_1 !== "OK") {
            Toast.fire({
              icon: "error",
              title: req1.data.errorCode + ": " + req1.data.errorMessage,
            });
          } else if (res_2 !== "OK") {
            Toast.fire({
              icon: "error",
              title: req2.data.errorCode + ": " + req2.data.errorMessage,
            });
          } else if (req3.data.status !== "OK") {
            Toast.fire({
              icon: "error",
              title: req3.data.errorCode + ": " + req3.data.errorMessage,
            });
          } else if (res_3.data.status !== "OK") {
            Toast.fire({
              icon: "error",
              title: req4.data.errorCode + ": " + req4.data.errorMessage,
            });
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("admintoast.settingfail"),
            });
          }
          // this.$emit('closesetPermissionFile')
        }
        //this.$emit('closesetPermissionFile')
        this.closesetpermission();
      } catch (err) {
        this.closesetpermission();
        //this.$emit('closesetPermissionFile')
        Toast.fire({
          icon: "error",
          title: err,
        });
      }
    },
    async setpermissionfile_v2() {
      this.loaddataprogress = true;
      this.loaddataprogressstaff = true;
      let checked_using_api = this.check_api_permission.filter((item) => 
        item.use_api === true
      )
      
      if(this.filedata.file_type === "folder" && this.permission_clone && checked_using_api.length === 0) {
        let check_from_tab = [
          this.data_permission_business.permision_status,
          this.data_permission_department.permision_status,
          this.data_permission_role.permision_status,
          this.chipstaff === 'blue-grey lighten-3' ? false : true
        ]
        this.check_api_permission.forEach((item, index) => {
          if(check_from_tab[index] === true) {
            item.use_api = true
          }
        })
        checked_using_api = this.check_api_permission.filter((item) => 
          item.use_api === true
        )
      }
      if(checked_using_api.length === 0) { //ไม่ได้มีแก้ไขอะไร
        //this.$emit('closesetPermissionFile')
        this.loaddataprogress = false;
        this.loaddataprogressstaff = false;
        Toast.fire({
          icon: "success",
          title: this.$t("admintoast.settingsuccess"),
        });
        //this.$emit('closesetPermissionFile')
        this.test = 1;
        this.selectdepartment = "";
        this.closesetpermission()
        return
      }
      console.log("checkkk usign ", checked_using_api)

      checked_using_api.forEach( async (item, index) => {
        let data_api = {}
        let payload
        let file_type = "";
        if (this.filedata.file_type === "folder") {
          file_type = "folder";
        } else {
          file_type = "file";
        }
        if(item.name === 'business') {
          data_api['api'] = "/api/setting_business_permission"
          payload = {
            data_id: this.filedata.file_id,
            data_type: file_type,
            account_id: this.dataAccountId,
            clone: this.permission_clone === true ? "True" : "False",
            status_setting: this.settostring(this.data_permission_business.permision_status),
            permission_business: this.settostring(
              this.data_permission_business.permission_active
            )
          }
          data_api['payload'] = payload

        } else if (item.name === 'department') {
          //  COMPARE ORIGINAL PERMISSION
          data_api['api'] = "/api/setting_department_permission"
          let allow_department = []
          let originl_permission = JSON.parse(this.backup_compare_department)
          this.data_permission_department.permission_value.forEach((itemPermission, index) => {
            console.log("item department ", itemPermission)
            if (JSON.stringify(itemPermission.permission_active) !== JSON.stringify(originl_permission.permission_value[index].permission_active)) {
              itemPermission['permission_active'] = this.settostring(itemPermission.permission_active)
              let object_dep = {}
              object_dep[`${itemPermission.id}`] = itemPermission['permission_active']
              allow_department.push(object_dep)
            }
          })
          payload = {
            account_id: this.dataAccountId,
            allow_department: allow_department,
            data_id: this.filedata.file_id,
            data_type: file_type,
            clone: this.permission_clone === true ? "True" : "False",
            status_setting: this.settostring(this.data_permission_department.permision_status),
          }
          data_api['payload'] = payload
        } else if (item.name === 'role') {
          data_api['api'] = "/api/setting_data_permission_role"
          let allow_role = []
          let originl_permission = JSON.parse(this.backup_compare_role)
          this.data_permission_role.permission_value.forEach((itemPermission, index) => {
            console.log("item role ", itemPermission)
            if (JSON.stringify(itemPermission.permission_active) !== JSON.stringify(originl_permission.permission_value[index].permission_active)) {
              itemPermission['permission_active'] = this.settostring(itemPermission.permission_active)
              let object_dep = {}
              object_dep[`${itemPermission.id}`] = itemPermission['permission_active']
              allow_role.push(object_dep)
            }
          })
          payload = {
            account_id: this.dataAccountId,
            allow_role: allow_role,
            data_id: this.filedata.file_id,
            data_type: file_type,
            clone: this.permission_clone === true ? "True" : "False",
            status_setting: this.settostring(this.data_permission_role.permision_status),
          }
          data_api['payload'] = payload
        } else if (item.name === 'employee') {
          data_api['api'] = "/api/setting_data_permission"
          let allow_account = []
          let originl_permission = JSON.parse(this.backup_compare_staff)
          this.buff_loop_staff.forEach((itemPermission, index) => {
            if (JSON.stringify(itemPermission) !== JSON.stringify(originl_permission[index])) {
              itemPermission['permission_data'] = this.settostring(itemPermission.permission_data)
              let object_dep = {}
              object_dep[`${itemPermission.staff_id}`] = itemPermission['permission_data']
              allow_account.push(object_dep)
            }
          })
          payload = {
            account_id: this.dataAccountId,
            allow_account: allow_account,
            data_id: this.filedata.file_id,
            data_type: file_type,
            clone: this.permission_clone === true ? "True" : "False",
            status_setting: this.settostring(this.permission_file),
          }
          data_api['payload'] = payload
        }

        try {
          let auth = await gbfGenerate.generateToken();
          let response = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES +
            data_api['api'],
          data: data_api['payload'],
          headers: { Authorization: auth.code },
        });
        
          console.log("response ", response)
          if(checked_using_api.length-1 === index) {
            this.loaddataprogress = false;
            this.loaddataprogressstaff = false;
            //this.$emit('closesetPermissionFile')
            Toast.fire({
              icon: "success",
              title: this.$t("admintoast.settingsuccess"),
            });
            //this.$emit('closesetPermissionFile')
            this.test = 1;
            this.selectdepartment = "";
            this.closesetpermission();
          }
        } catch (err) {
          this.closesetpermission();
          //this.$emit('closesetPermissionFile')
          Toast.fire({
            icon: "error",
            title: err,
          });
        }

      })

    },

    dialogUpload() {
      //console.log("dialogupload");
      this.opendialogupload = true;
    },
    seticon(parameter) {
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video-outline", "purple"];
      } else {
        dataicon = ["mdi-file-question-outline", "black"];
      }

      return dataicon;
    },
    formatdatetime(_datetime) {
      let dateyear =
        _datetime.split("")[0] +
        _datetime.split("")[1] +
        _datetime.split("")[2] +
        _datetime.split("")[3];
      let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
      let dateday = _datetime.split("")[6] + _datetime.split("")[7];
      let hour = _datetime.split("")[8] + _datetime.split("")[9];
      let minute = _datetime.split("")[10] + _datetime.split("")[11];
      let second = _datetime.split("")[12] + _datetime.split("")[13];

      return (
        dateday +
        "/" +
        datemonth +
        "/" +
        dateyear +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    setformatdate(_date) {
      let data =
        _date.substring(8, 6) +
        "/" +
        _date.substring(6, 4) +
        "/" +
        _date.substring(0, 4);
      return data || "-";
    },
  },
};
</script>
<style>
#thiscontainer2 {
  height: calc(101vh - 400px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>
